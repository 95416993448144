import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import * as moment from 'moment-timezone';
import {DEFAULT_TIMEZONE} from '../../i18n/i18n-defaults';
import {APP_CONFIG_TOKEN, AppConfig} from '../../app.config';
import {Subject} from 'rxjs';
import {ApiService} from '../../api/api.service';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

    private destroyed = new Subject<void>();
    version: string;
    cookiePolicyLink: string;

    constructor(@Inject(APP_CONFIG_TOKEN) private readonly cfg: AppConfig,
                private readonly apiService: ApiService) {
    }

    ngOnInit() {
        this.cookiePolicyLink = this.cfg.cookiePolicyLink;
        this.apiService.getPmsVersion().pipe(
            first()
        ).subscribe(ver => {
            this.version = ver;
        });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    get copyright(): string {
        let tz;
        try {
            tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (e) {
            tz = DEFAULT_TIMEZONE;
        }
        return (environment.copyright as string).replace('$YEAR$', moment.tz(tz).year().toString());
    }

    get address(): string {
        return environment.address as string;
    }
}
