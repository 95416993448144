// useful validation functions

import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as _ from 'lodash';
import {isDefined} from './commons';

/**
 * Validates the value of a controller, expects it to be a non-empty string.
 * @param ctrl The controller to be validated
 * @returns Null if controller is valid, a proper error code otherwise.
 */
export function validateNotBlank(ctrl: AbstractControl): ValidationErrors {
    const v = ctrl.value;
    if (typeof v === 'string' && (v as string).trim().length > 0) {
        return null;
    }

    return {empty: true};
}

/**
 * Validates the value of a controller, expects it to be an integer.
 * @param ctrl The controller to be validated
 * @returns Null if controller is valid, a proper error code otherwise.
 */
export function validateInteger(ctrl: AbstractControl): ValidationErrors {
    const v = ctrl.value;
    if (v === null || typeof v === 'undefined') {
        return null;
    }

    if (_.isSafeInteger(v)) {
        return null;
    }

    if (_.isString(v)) {
        try {
            const n = Number(v);
            if (_.isSafeInteger(n)) {
                return null;
            }
        } catch (e) {
            return {not_a_integer: true};
        }
    }

    return {not_a_integer: true};
}

/**
 * Validates the value of a controller, expects it to be a float number.
 * If the min parameter is included, the control value HAS to be higher than it.
 * @param ctrl The controller to be validated
 * @param lang Current language
 * @param min The minimum value the control has to be higher than
 * @returns Null if controller is valid, a proper error code otherwise.
 */
export function validateFloat(ctrl: AbstractControl, lang?: string, min?: number): ValidationErrors {
    const v = ctrl.value;

    if (v === null || typeof v === 'undefined') {
        return null;
    }

    if (_.isNumber(v)) {
        return null;
    }
    if (_.isString(v)) {
        try {
            let n;
            if (lang) {
                if (lang === 'it' && v.includes('.') || lang === 'en' && v.includes(',')) {
                    return {not_a_float: true};
                }
                const convertedValue = v.replace(',', '.');
                n = Number(convertedValue);
            } else {
                n = Number(v);
            }
            if (_.isFinite(n)) {
                if (isDefined(min)) {
                    return n > min ? null : {below_min: true};
                } else {
                    return null;
                }
            }
        } catch (e) {
            return {not_a_float: true};
        }
    }

    return {not_a_float: true};
}

/**
 * Creates a validator checking that the value of a controller is a string array (with the given separator).
 *
 * @param separator The separator to be used for string splitting
 * @param minLength Minimum acceptable number of strings in the array
 * @returns Null if controller is valid, a proper error code otherwise.
 */
export function validateStringArray(separator: string, minLength: number = 0): ValidatorFn {
    return ctrl => {
        const v = ctrl.value;
        if (v === null || typeof v === 'undefined') {
            return null;
        }

        if (_.isString(v)) {
            const arr = v.split(separator).map(s => s.trim());
            if (arr.length >= minLength && arr.every(s => s.length > 0)) {
                return null;
            }
        }

        return {not_string_array: true};
    };
}

/**
 * Creates a validator checking that the value of a controller is an integer array (with the given separator).
 *
 * @param separator The separator to be used for string splitting
 * @returns Null if controller is valid, a proper error code otherwise.
 */
export function validateIntegerArray(separator: string): ValidatorFn {
    return ctrl => {
        const v = ctrl.value;
        if (v === null || typeof v === 'undefined') {
            return null;
        }

        if (_.isSafeInteger(v)) {
            return null;
        }

        if (_.isString(v)) {
            try {
                if (v.split(separator).map(vv => Number(vv)).every(vv => _.isSafeInteger(vv))) {
                    return null;
                }
            } catch (e) {
            }
        }

        return {not_integer_array: true};
    };
}

/**
 * Validates the value of a controller, expects it to be the right object for the
 * NgBootstrao date picker component.
 * @param ctrl The controller to be validated
 * @returns Null if controller is valid, a proper error code otherwise.
 */
export function validateNgbDate(ctrl: AbstractControl): ValidationErrors {
    const v = ctrl.value;

    if (!v || !v.year || !v.month || !v.day) {
        return {not_a_date: true};
    }

    return null;
}

/**
 * Validates two controllers at a time, expects the value of the first to be greater than
 * or equal to the value of the second.
 * @param src The source controller
 * @param tgt The target controller
 * @param prefix Error prefix
 * @param suffix Error suffix
 * @returns Null if controller is valid, a proper error code otherwise.
 */
export function greaterThanOrEqualTo(src: AbstractControl, tgt: AbstractControl, prefix?: string, suffix?: string): ValidationErrors {
    const s = src.value;
    const t = tgt.value;

    if (_.isNumber(s) && _.isNumber(t) && s < t) {
        const err = {};
        const code = `${isDefined(prefix) ? prefix + '_' : ''}not_gt_or_eq${isDefined(suffix) ? '_' + suffix : ''}`;
        err[code] = true;
        return err;
    }

    return null;
}

/**
 * Creates a validator that checks if the controller value is strictly greater than the given
 * lower bound.
 * @param min The lower bound value
 * @returns The validator function
 */
export function validateGt(min: number): (ctrl: AbstractControl) => ValidationErrors {
    return ctrl => {
        const v = ctrl.value;

        if (_.isNumber(v) && v <= min) {
            return {not_gt: true};
        }

        return null;
    };
}

/**
 * Validates two controllers at a time, expects they have equal values.
 * @param src The source controller
 * @param tgt The target controller
 * @returns Null if controller is valid, a proper error code otherwise.
 */
export function validateEq(src: AbstractControl, tgt: AbstractControl): ValidationErrors {
    const s = src.value;
    const t = tgt.value;

    if (s !== t) {
        return {not_eq: true};
    }

    return null;
}

/**
 * Creates a validator that checks that a controller's value does not belong to the given set of values.
 *
 * @param values The set of values to compare with
 * @returns The validator function
 */
export function validateNotDuplicate(values: (string | number)[]): ValidatorFn {
    return ctrl => {
        if (values.indexOf(ctrl.value) >= 0) {
            return {duplicate: true};
        }

        return null;
    };
}

/**
 * Creates a validator that checks the value of a controller, expecting it not to be blank (after trimming with the given character set).
 * @param chars The set of characters to trim with
 * @returns The validator function
 */
export function validateNotBlankTrim(chars: string[]): ValidatorFn {
    return ctrl => {
        const v = ctrl.value;
        if (typeof v === 'string' && trimChars(v as string, chars).length > 0) {
            return null;
        }

        return {empty: true};
    };
}

function trimChars(str, chars) {
    let start = 0;
    let end = str.length;

    while (start < end && chars.indexOf(str[start]) >= 0) {
        ++start;
    }

    while (end > start && chars.indexOf(str[end - 1]) >= 0) {
        --end;
    }

    return (start > 0 || end < str.length) ? str.substring(start, end) : str;
}

export function clearErrors(errors: object, key: string): object | null {
    if (!errors) {
        return null;
    }
    const newErrors = {...errors};
    delete newErrors[key];
    if (!Object.keys(newErrors).length) {
        return null;
    }
    return newErrors;
}
