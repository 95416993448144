<mat-toolbar class="app-header mat-elevation-z3">
    <mat-toolbar-row>
        <div fxLayout="row" fxFill fxLayoutAlign="space-between center">
            <div>
                <a routerLink="/c" (click)="closeSideMenu()">
                    <img alt="Rose logo" src="assets/logo.png" class="header-logo" appBusy>
                </a>
            </div>
            <div *ngIf="!mobileView && !hideLinksInUpperBanner" fxLayoutGap="24px">
                <button *ngIf="SHOW_PILLS" mat-button [routerLink]="['/c', 'application-pills']" color="primary" class="header-button">
                    <span class="ml-8 header-button-text">{{'Informative pills'|i18n}}</span>
                </button>
                <button *ngIf="SHOW_INCENTIVES" mat-button [routerLink]="['/c', 'application-incentives']" color="primary" class="header-button">
                    <span class="ml-8 header-button-text">{{'State incentives'|i18n}}</span>
                </button>
                <button *ngIf="SHOW_FORM" mat-button [routerLink]="['/c', 'application-form']" color="primary" class="header-button">
                    <span class="ml-8 header-button-text">{{'How to participate'|i18n}}</span>
                </button>
            </div>
            <div *ngIf="mobileView && !hideLinksInUpperBanner">
                <ng-container *appVar="sideMenuShown|async as showSideMenu">
                    <button mat-button class="header-button"
                            (click)="toggleSideMenu(!showSideMenu)">
                        <app-icon name="fal-bars"></app-icon>
                    </button>
                </ng-container>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
