import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ToasterConfig} from 'angular2-toaster';
import {APP_CONFIG_TOKEN, AppConfig} from '../../app.config';
import {SideMenuService} from '../../shared/side-menu.service';
import {combineLatest, Observable, Subject} from 'rxjs';
import {MatSidenav} from '@angular/material/sidenav';
import {map, takeUntil} from 'rxjs/operators';
import {APP_CONFIG} from '../../app.config';

const MEDIUM_WIDTH_SIZE = 959; // lt-md
const SHOW_HOMEPAGE = APP_CONFIG.showHomePage;
const SHOW_PILLS = APP_CONFIG.showPills;
const SHOW_INCENTIVES = APP_CONFIG.showIncentives;
const SHOW_FORM = APP_CONFIG.showForm;

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

    private readonly destroyed = new Subject();
    @ViewChild('sideMenu') private sideMenu: MatSidenav;
    toasterConfig: ToasterConfig;
    hideLinksInUpperBanner: boolean;
    showSideMenu: Observable<boolean>;

    // Exported in HTML
    SHOW_HOMEPAGE = SHOW_HOMEPAGE;
    SHOW_PILLS = SHOW_PILLS;
    SHOW_INCENTIVES = SHOW_INCENTIVES;
    SHOW_FORM = SHOW_FORM;

    constructor(@Inject(APP_CONFIG_TOKEN) private readonly cfg: AppConfig,
                private readonly sideMenuService: SideMenuService) {
        this.toasterConfig = new ToasterConfig({
            animation: 'fade',
            positionClass: 'toast-top-center',
            limit: 1,
            tapToDismiss: true,
            showCloseButton: false,
            newestOnTop: true,
            timeout: cfg.toasterTimeout,
            mouseoverTimerStop: false
        });
        this.hideLinksInUpperBanner = [SHOW_HOMEPAGE, SHOW_FORM, SHOW_PILLS, SHOW_INCENTIVES].filter(d => d === true).length === 1;
    }

    ngOnInit() {

        this.showSideMenu = combineLatest([this.sideMenuService.sideMenuEnabledStatus, this.sideMenuService.sideMenuShowStatus]).pipe(
            map(([sideMenuEnabled, sideMenuShow]) => sideMenuEnabled && sideMenuShow),
            takeUntil(this.destroyed)
        );
    }

    closeSideMenu() {
        this.sideMenuService.hideSideMenu();
    }
}
