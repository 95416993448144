import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {behead, untail} from '../commons/commons';
import {APP_CONFIG_TOKEN, AppConfig} from '../app.config';
import {Observable} from 'rxjs';
import {DocConfig} from './dto';
import {map} from 'rxjs/operators';
import {
    ConfigurabilityFieldsFrontEnd,
    PreMemberCommunity,
    PreMemberUtility
} from 'pms-commons/premember';


const API_PREFIX = '';

/**
 * Api service that deals with (TOTP-authenticated) "application" API of api-server
 */
@Injectable()
export class ApiService {

    constructor(private readonly http: HttpClient,
                @Inject(APP_CONFIG_TOKEN) private readonly cfg: AppConfig) {
    }

    getDocTypes(): Observable<DocConfig[]> {
        return this.http.get(mkUrl(this.cfg, `${API_PREFIX}/config/doc`))
            .pipe(map(json => json as DocConfig[]));
    }

    getFieldsConfigurability(): Observable<ConfigurabilityFieldsFrontEnd> {
        return this.http.get(mkUrl(this.cfg, `${API_PREFIX}/config/fields`))
            .pipe(map(json => json as ConfigurabilityFieldsFrontEnd));
    }

    getPmsVersion(): Observable<string> {
        return this.http.get(mkUrl(this.cfg, `${API_PREFIX}/version`))
            .pipe(map(json => json as string));
    }

    getPrivacyPolicyLink(): Observable<string> {
        return this.http.get(mkUrl(this.cfg, `${API_PREFIX}/privacy-policy`))
            .pipe(map(json => json as string));
    }

    sendApplicationRequest(formData: FormData): Promise<void> {
        return this.http.post(mkUrl(this.cfg, `${API_PREFIX}/new-request`), formData)
            .pipe(map(resp => resp as undefined))
            .toPromise();
    }

    sendEmailRecap(recap): Promise<void> {
        return this.http.post(mkUrl(this.cfg, `${API_PREFIX}/email`), recap)
            .pipe(map(resp => resp as undefined))
            .toPromise();
    }

    isLinkedToEcm(): Observable<boolean> {
        return this.http.get(mkUrl(this.cfg, `${API_PREFIX}/ecm`))
            .pipe(map(json => json as boolean));
    }

    updateYearlyConsumption(utility: PreMemberUtility): Promise<number> {
        return this.http.post(mkUrl(this.cfg, `${API_PREFIX}/yearly-consumption`), utility)
            .pipe(map(resp => resp as number))
            .toPromise();
    }

    getInstanceCommunities(): Observable<PreMemberCommunity[] | null> {
        return this.http.get(mkUrl(this.cfg, `${API_PREFIX}/communities`))
            .pipe(map(json => json as PreMemberCommunity[] | null));
    }

    // isPodExisting(podId: string): Observable<BooleanResponse> {
    //     return this.http.get(mkUrl(this.cfg, `${API_PREFIX}/exist/pod/${podId}`))
    //         .pipe(map(json => json as BooleanResponse));
    // }

}

/**
 * Prepares the API endpoint URL.
 *
 * @param cfg Auth configuration
 * @param path The relative path to the exact endpoint
 * @return string The endpoint URL string
 */
function mkUrl(cfg: AppConfig, path: string): string {
    return mkUrlOrigin(cfg.domainPrefix, mkUrlPath(cfg.rootPath, path));
}

function mkUrlPath(rootPath: string, path: string): string {
    return rootPath ? untail(rootPath, '/') + '/' + behead(path, '/') : path;
}

function mkUrlOrigin(domainPrefix: string, path: string): string {
    const url = new URL(location.origin);
    url.pathname = path;
    url.hostname = `${domainPrefix || ''}${url.hostname}`;
    return url.href;
}
