import {Component, OnInit} from '@angular/core';

const whatParagraph = 'The <b>Renewable Energy Community (REC)</b> is a legal entity, formed by individuals, small and medium-sized enterprises (SMEs), territorial entities or local authorities.';
const goalParagraph = 'Let the community members produce and share <b>renewable energy</b> through the existing distribution network. In order to generate and manage cost-effective green energy themselves and reduce CO2 emissions.';
const membersParagraph = 'Participation doesn\'t cost any money and members join voluntarily, even those who do not own a plant (consumers), as long as the feed-in and withdrawal points are subtended to the same High/Medium Voltage transformer (primary cabin).';
const needToKnowParagraph = 'Italy is seeing an increase in energy communities, but still many don\'t know what they are.';
const explainParagraph = 'We can explain it easily:';
const billsParagraph = 'Using communities generated energy, you will receive a state incentive as reward for your efforts.';
const billsPt2Paragraph = 'Even if you don\'t own a plant, you will be able to utilize energy generated by your community\'s plants.';
const participateParagraph = 'If you want be a part of an energy community, send us your request.';
const participatePt2Paragraph = 'A community may be waiting for you!';

@Component({
    selector: 'app-application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {

    FIRST = whatParagraph;
    SECOND = goalParagraph;
    THIRD = membersParagraph;
    NEED = needToKnowParagraph;
    EXPLAIN = explainParagraph;
    BILLS = billsParagraph;
    BILLS_PT2 = billsPt2Paragraph;
    PARTICIPATE = participateParagraph;
    PARTICIPATE_PT2 = participatePt2Paragraph;


    constructor() {


    }


    ngOnInit() {

    }


}

