<div class="pill-content-container">
    <div *ngIf="SHOW_BREADCRUMB" class="top-banner" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="6px">
        <a [routerLink]="['/c', 'application']">{{'Energy communities'|i18n}}</a>
        <div fxLayout="row" fxLayoutGap="6px">
            <span class="major-sign">></span><a [routerLink]="['/c', 'application-pills']">{{'Informative pills'|i18n}}</a>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
            <span class="major-sign">></span><a [routerLink]="['/c', 'pill-content', (pill.id).toString()]">{{pill.title|i18n}}</a>
        </div>
    </div>
    <div class="pill-explanation" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="72px">
        <div class="pills-title">
            {{(pill.title|i18n).toUpperCase()}}
        </div>
        <div fxLayoutAlign="center">
            <img alt="Pill image" [src]=pill.backgroundHeading class="pill-image" appBusy>
        </div>
        <div *ngIf="pill.description" class="paragraph-text">
            <ng-container *ngFor="let desc of pill.description">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <p>{{desc}}</p>
                </div>
            </ng-container>
        </div>
        <button app-primary-button [routerLink]="['/c', 'application-pills']"
                class="go-back-button" fxLayoutAlign="center center">
            <app-icon name="fal-arrow-left"></app-icon>
            <span class="ml-8">{{'Go back to the informative pills'|i18n}}</span>
        </button>
    </div>
</div>
