<div class="content-layout" fxLayout="column" fxLayoutAlign="space-between">
    <div>
        <!-- Toaster -->
        <toaster-container [toasterconfig]="toasterConfig"></toaster-container>

        <!-- Header -->
        <app-header class="header"></app-header>

        <mat-sidenav-container class="container" (backdropClick)="closeSideMenu()">
            <mat-sidenav *ngIf="!hideLinksInUpperBanner" class="sidebar-class" #sideMenu [opened]="showSideMenu|async"
                         [mode]="'over'"
                         [position]="'end'"
                         mode="side">
                <div class="mt-12" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <button *ngIf="SHOW_PILLS" mat-button color="primary" [routerLink]="['/c', 'application-pills']" class="header-button"
                    (click)="closeSideMenu()">
                        <span class="ml-8 header-button-text">{{'Informative pills'|i18n}}</span>
                    </button>
                    <button *ngIf="SHOW_INCENTIVES" mat-button color="primary" [routerLink]="['/c', 'application-incentives']" class="header-button"
                    (click)="closeSideMenu()">
                        <span class="ml-8 header-button-text">{{'State incentives'|i18n}}</span>
                    </button>
                    <button *ngIf="SHOW_FORM" mat-button color="primary" [routerLink]="['/c', 'application-form']" class="header-button"
                    (click)="closeSideMenu()">
                        <span class="ml-8 header-button-text">{{'How to participate'|i18n}}</span>
                    </button>
                </div>
            </mat-sidenav>
            <mat-sidenav-content class="content o-visible_">
                <router-outlet></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>

<!--        <mat-sidenav-container class="container">-->
<!--            <mat-sidenav-content class="content o-visible_">-->
<!--                <router-outlet></router-outlet>-->
<!--            </mat-sidenav-content>-->
<!--        </mat-sidenav-container>-->
    </div>
    <!-- Footer -->
    <app-footer></app-footer>
</div>
