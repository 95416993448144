<div class="footer" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-lg="column"
     fxLayoutAlign.lt-lg="space-evenly center">
    <img src="../../../assets/logo_footer.png" class="footer-logo" [alt]="'logo'|i18n">
    <div fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="12px">
        <span><b>{{copyright}}</b></span>
        <span class="version-text">{{'Version: {0}'|i18n:version}}</span>
    </div>
    <div fxLayout="column">
        <span class="new-line">{{address}}</span>
        <span>
            <a [href]=cookiePolicyLink target="_blank" rel="noopener noreferrer">Cookie Policy</a>
        </span>
    </div>
</div>
