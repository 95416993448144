import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {APP_CONFIG, APP_CONFIG_TOKEN, AppConfig} from '../../../app.config';
import {Pills} from '../../../commons/commons.config';

const SHOW_BREADCRUMB = APP_CONFIG.showBreadCrumb;

@Component({
    selector: 'app-pill-content',
    templateUrl: './pill-content.component.html',
    styleUrls: ['./pill-content.component.scss']
})
export class PillContentComponent implements OnInit {

    pillId: string;
    pill: Pills;

    // Exported in HTML
    SHOW_BREADCRUMB = SHOW_BREADCRUMB;

    constructor(@Inject(APP_CONFIG_TOKEN) private readonly cfg: AppConfig,
                private readonly route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.pillId = params.id;
            // Get all the pill content
            this.pill = this.cfg.pills.filter(pill => pill.id === Number(this.pillId))[0];
        });
    }

}
