<div class="google-maps-container">
    <form class="address-form">
        <div fxLayout="column">
            <div fxFlex app-input-container class="address-field">
                <input #search app-input
                       class="input-font"
                       autocapitalize="off" spellcheck="false"
                       (keydown.enter)="$event.preventDefault()"
                       (focusout)="loseFocus($event)"
                       [placeholder]="'Enter address'|i18n">
                <mat-hint *ngIf="!isDefined(address) || address.errors.length==0" class="txt-s-12-4">
                    {{ 'Type in street or square, house number and location and click on the option that appears in the drop-down.'|i18n}}
                </mat-hint>
            </div>
            <div *ngFor="let error of address?.errors" app-error>
                {{error|i18n}}
            </div>
        </div>
    </form>
</div>
