import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {SpatialCoordinates} from 'smag-commons/cfg/hierarchy';
import {MapsAPILoader} from '@agm/core';
import {isDefined} from '../../commons/commons';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Subject} from 'rxjs';

export interface Address {
    street: string;
    streetNumber: string;
    postalCode: string;
    locality: string;
    municipality: string;
    province: string;
    region: string;
    country: string;
    coordinates: SpatialCoordinates;
    isValid: boolean;
    errors: string[];
}

const INITIAL_ZOOM = 16;

export enum GoogleTypes {
    POLITICAL = 'political', // ?
    STREET_NUMBER = 'street_number',
    ROUTE = 'route',
    LOCALITY = 'locality',
    ADMINISTRATIVE_AREA_LEVEL_3 = 'administrative_area_level_3', // municipality
    ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2', // province
    ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1', // region
    COUNTRY = 'country', // country
    POSTAL_CODE = 'postal_code', // postal code
}

export const googleZoneMap: { zone: string, regions: string[] }[] = [
    {zone: 'CNOR', regions: ['Toscana', 'Marche']},
    {zone: 'CSUD', regions: ['Lazio', 'Abruzzo', 'Campania', 'Umbria']},
    {
        zone: 'NORD', regions: ['Valle d\'Aosta', 'Piemonte', 'Liguria', 'Lombardia',
            'Trentino-Alto Adige', 'Veneto', 'Friuli-Venezia Giulia', 'Emilia-Romagna']
    },
    {zone: 'SARD', regions: ['Sardegna']},
    {zone: 'SICI', regions: ['Sicilia']},
    {zone: 'SUD', regions: ['Molise', 'Puglia', 'Basilicata']},
    {zone: 'CALA', regions: ['Calabria']},
];

@Component({
    selector: 'app-google-maps-address-autocomplete',
    templateUrl: './google-maps-address-autocomplete.component.html',
    styleUrls: ['./google-maps-address-autocomplete.component.scss']
})
export class GoogleMapsAddressAutocompleteComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    private autocomplete: google.maps.places.Autocomplete;
    private autocompleteListener: google.maps.MapsEventListener;
    private clickListener: google.maps.MapsEventListener;
    address: Address;

    @ViewChild('search', {read: ElementRef}) private searchElementRef: ElementRef;
    @Output() addressChange: EventEmitter<Address> = new EventEmitter<Address>();
    @Input() formControlRef: FormControl;
    @Input() initialAddress: string;
    @Input() isRequired: boolean;
    @Input() manageCopyAddress: Subject<[string, string]> = new Subject<[string, string]>();
    @Input() currentKey: string;

    readonly isDefined = isDefined;


    constructor(private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone,
                private changeDetectorRef: ChangeDetectorRef) {
        this.autocomplete = null;
        this.autocompleteListener = null;
        this.clickListener = null;
        this.address = null;
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.initialAddress && this.formControlRef) {
            throw new Error('Cannot pass both input parameters');
        }
        if (this.initialAddress) {
            this.searchElementRef.nativeElement.value = this.initialAddress;
        } else {
            if (this.formControlRef) {
                this.searchElementRef.nativeElement.value = this.formControlRef.value;
            }
        }
        if (this.manageCopyAddress && this.currentKey) {
            this.manageCopyAddress.subscribe(([key, value]) => {
                if (key && value) {
                    if (key === this.currentKey) {
                        if (this.address) {
                            this.address.errors = [];
                            this.address.isValid = true;
                            this.formControlRef.setValidators([minValidator(this.address)]);
                        }
                        this.searchElementRef.nativeElement.value = value;
                    }
                }
            });
        }
        this.searchElementRef.nativeElement.addEventListener('change', () => {
            if (this.searchElementRef.nativeElement.value === '') {
                this.address = {
                    street: null,
                    streetNumber: null,
                    postalCode: null,
                    locality: null,
                    municipality: null,
                    province: null,
                    region: null,
                    country: null,
                    coordinates: null,
                    isValid: !this.isRequired,
                    errors: this.isRequired ? ['Missing address'] : []
                };
                this.formControlRef.setValidators([minValidator(this.address)]);
                this.formControlRef.setValue(null);
                this.addressChange.emit(null);
            }
        });
        this.mapsAPILoader.load().then(() => {
            this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                componentRestrictions: {country: 'it'},
                types: ['address']
            });
            // this.autocomplete.setTypes(['regions']);
            this.autocompleteListener = this.autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    // get the place result
                    const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
                    this.address = {
                        street: null,
                        streetNumber: null,
                        postalCode: null,
                        locality: null,
                        municipality: null,
                        province: null,
                        region: null,
                        country: null,
                        coordinates: null,
                        isValid: false,
                        errors: []
                    };
                    // verify result
                    if (!isDefined(place.geometry)) {
                        if (place.name === '') {
                            if (this.isRequired) {
                                this.address.errors = ['Missing address'];
                                this.address.isValid = false;
                            } else {
                                this.address.errors = [];
                                this.address.isValid = true;
                            }
                        } else {
                            this.address.errors.push('Invalid address. Search the nearest address');
                        }
                        this.addressChange.next(this.address);
                        this.formControlRef.setValidators([minValidator(this.address)]);
                        this.formControlRef.setValue(place.formatted_address);
                        return;
                    }
                    this.changeDetectorRef.markForCheck();
                    // set latitude, longitude
                    const coordinates = {
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng()
                    };
                    // response parser
                    place.address_components.forEach(c => {
                        if (c.types.includes(GoogleTypes.STREET_NUMBER)) {
                            this.address.streetNumber = c.long_name;
                        }
                        if (c.types.includes(GoogleTypes.ROUTE)) {
                            this.address.street = c.long_name;
                        }
                        if (c.types.includes(GoogleTypes.LOCALITY)) {
                            this.address.locality = c.long_name;
                        }
                        if (c.types.includes(GoogleTypes.ADMINISTRATIVE_AREA_LEVEL_3)) {
                            this.address.municipality = c.long_name;
                        }
                        if (c.types.includes(GoogleTypes.ADMINISTRATIVE_AREA_LEVEL_2)) {
                            this.address.province = c.short_name;
                        }
                        if (c.types.includes(GoogleTypes.ADMINISTRATIVE_AREA_LEVEL_1)) {
                            this.address.region = c.short_name;
                        }
                        if (c.types.includes(GoogleTypes.POSTAL_CODE)) {
                            this.address.postalCode = c.long_name;
                        }
                        if (c.types.includes(GoogleTypes.COUNTRY)) {
                            this.address.country = c.long_name;
                        }
                    });
                    this.address.coordinates = coordinates;
                    this.address.errors = validate(this.address);
                    this.address.isValid = this.address.errors.length === 0;
                    this.addressChange.next(this.address);
                    if (this.formControlRef) {
                        const a = this.address;
                        this.formControlRef.setValidators([minValidator(this.address)]);
                        this.formControlRef.setValue(place.formatted_address);
                    }
                });
            });
        });
    }


    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy() {
        if (this.autocomplete) {
            this.autocomplete.unbindAll();
        }
        if (this.autocompleteListener) {
            this.autocompleteListener.remove();
        }
        if (this.clickListener) {
            this.clickListener.remove();
        }
    }

    loseFocus($event: FocusEvent) {
        google.maps.event.trigger(this.searchElementRef.nativeElement, 'focus', {});
        google.maps.event.trigger(this.searchElementRef.nativeElement, 'keydown', {keyCode: 13});
    }


}

function validate(address: Address): string[] {
    const errors: string[] = [];
    if (isEmpty(address.streetNumber)) {
        errors.push('Missing street number: insert it after the street name with a comma');
    }
    if (isEmpty(address.street)) {
        errors.push('Missing street name');
    }
    if (isEmpty(address.municipality)) {
        errors.push('Missing municipality');
    }
    if (isEmpty(address.province)) {
        errors.push('Missing province');
    }
    if (isEmpty(address.region)) {
        errors.push('Missing region');
    }
    // if (isEmpty(address.postalCode)) {
    //     errors.push('Missing postal code');
    // }
    if (!address.coordinates.latitude) {
        errors.push('Missing latitude');
    }
    if (!address.coordinates.longitude) {
        errors.push('Missing longitude');
    }
    return errors;
}

function isEmpty(s: string): boolean {
    return !s || s.trim().length === 0;
}

function minValidator(address: Address): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return !address.isValid ? {invalid: true} : null;
    };
}

function areAllAttributesNull(obj: any): boolean {
    for (const key in obj) {
        if (obj[key] !== null) {
            return false;
        }
    }
    return true;
}
