import {Pipe, PipeTransform} from '@angular/core';

import * as moment from 'moment-timezone';
import {Moment} from 'moment-timezone';

import {I18NService} from './i18n.service';
import {DEFAULT_FORMATS, DEFAULT_TIMEZONE} from './i18n-defaults';
import {isDefined} from '../commons/commons';

@Pipe({name: 'i18n'})
export class I18NPipe implements PipeTransform {

    constructor(private readonly i18nService: I18NService) {
    }

    transform(value: string, ...args: any[]): any {
        return this.i18nService.translate(value, ...args);
    }

}

@Pipe({name: 'i18nParse'})
export class I18NParsePipe implements PipeTransform {

    constructor() {
    }

    // value is assumed to be a string in ISO 8601 format
    transform(value: string): Moment | null {
        return value ? moment(value) : null;
    }

}

@Pipe({name: 'i18nDate'})
export class I18NDatePipe implements PipeTransform {

    constructor(private readonly i18n: I18NService) {
    }

    // value is assumed to be a valid `moment` object (or a posix timestamp in milliseconds)
    transform(value: Date | Moment | number, format?: string): string | null {
        const timeZone = DEFAULT_TIMEZONE;
        return value ? moment(value).tz(timeZone).format(format || this.i18n.dateShortFormat(DEFAULT_FORMATS.DATE)) : null;
    }

}

@Pipe({name: 'i18nDateTime'})
export class I18NDateTimePipe implements PipeTransform {

    constructor(private readonly i18n: I18NService) {
    }

    // value is assumed to be a valid `moment` object (or a posix timestamp in milliseconds)
    transform(value: Date | Moment | number, format?: string): string | null {
        const timeZone = DEFAULT_TIMEZONE;
        return isDefined(value) ? moment(value).tz(timeZone).format(format || this.i18n.dateTimeShortFormat(DEFAULT_FORMATS.DATETIME)) : null;
    }

}

@Pipe({name: 'i18nTimestamp'})
export class I18NTimestampPipe implements PipeTransform {

    constructor(private readonly i18n: I18NService) {
    }

    // value is assumed to be a valid `moment` object (or a posix timestamp in milliseconds)
    transform(value: Date | Moment | number, format?: string): string | null {
        const timeZone = DEFAULT_TIMEZONE;
        return isDefined(value) ? moment(value).tz(timeZone).format(format || this.i18n.timestampFormat(DEFAULT_FORMATS.TIMESTAMP)) : null;
    }

}
