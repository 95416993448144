import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {filter, map, mergeMap, share, takeUntil} from 'rxjs/operators';
import {APP_CONFIG_TOKEN, AppConfig} from './app.config';
import {I18NService} from './i18n/i18n.service';
import {CURRENT_VIEW_TOKEN, CurrentView} from './commons/commons.module';

type ViewData = {
    title?: string;
};

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly destroyed = new Subject();

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly title: Title,
        private readonly i18n: I18NService,
        @Inject(APP_CONFIG_TOKEN) private readonly cfg: AppConfig,
        @Inject(CURRENT_VIEW_TOKEN) private readonly currentView: CurrentView<ViewData>) {
    }

    ngOnInit() {
        const navigationObs: Observable<NavigationEnd> = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(event => event as NavigationEnd),
            takeUntil(this.destroyed),
            share()
        );

        // set-up current view
        const viewDataSubj: ReplaySubject<ViewData> = new ReplaySubject<ViewData>(1);
        this.currentView.viewData = viewDataSubj.asObservable();

        navigationObs.pipe(
            map(() => {
                let route = this.route;
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter(route => (route as ActivatedRoute).outlet === 'primary'),
            mergeMap(route => ((route as ActivatedRoute).data as Observable<ViewData>)),
            takeUntil(this.destroyed)
        ).subscribe(viewData => {
            // update current view data
            viewDataSubj.next(viewData);
            // update page title
            // see: https://toddmotto.com/dynamic-page-titles-angular-2-router-events
            if (viewData.title) {
                this.title.setTitle(this.i18n.translate(viewData.title));
            }
        });

        navigationObs.pipe(
            takeUntil(this.destroyed)
        ).subscribe(event => {
            // NOTE this is a sort of hack that will help us redirect to /resources
            // if / url is hit; seems like there's no other way to do this
            if (event.url === '/') {
                this.router.navigate(['/c', 'application']);
            }
        });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
