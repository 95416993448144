import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                // if a text-like response was requested, but the call failed, decode the (JSON-encoded) error
                let error = err;
                if (request.responseType === 'text' && !err.ok && typeof err.error === 'string') {
                    try {
                        error = new HttpErrorResponse({
                            ...err,
                            error: JSON.parse(err.error)
                        });
                    } catch {
                    }
                }
                return throwError(error);
            })
        );
    }
}
