import {Component, HostListener, OnInit} from '@angular/core';
import {SideMenuService} from '../../shared/side-menu.service';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../../app.config';

const MEDIUM_WIDTH_SIZE = 959; // lt-md
const SHOW_HOMEPAGE = APP_CONFIG.showHomePage;
const SHOW_PILLS = APP_CONFIG.showPills;
const SHOW_INCENTIVES = APP_CONFIG.showIncentives;
const SHOW_FORM = APP_CONFIG.showForm;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    mobileView: boolean;
    hideLinksInUpperBanner: boolean;

    // Exported in HTML
    SHOW_HOMEPAGE = SHOW_HOMEPAGE;
    SHOW_PILLS = SHOW_PILLS;
    SHOW_INCENTIVES = SHOW_INCENTIVES;
    SHOW_FORM = SHOW_FORM;


    constructor(private readonly sideMenuService: SideMenuService) {
        this.mobileView = false;
        // The following means we only have one viewable page; So we hide the banner links
        this.hideLinksInUpperBanner = [SHOW_HOMEPAGE, SHOW_FORM, SHOW_PILLS, SHOW_INCENTIVES].filter(d => d === true).length === 1;
    }

    ngOnInit() {
        this.checkWidth();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkWidth();
    }

    get sideMenuShown(): Observable<boolean> {
        return this.sideMenuService.sideMenuShowStatus;
    }

    toggleSideMenu(showSideMenu: boolean) {
        if (showSideMenu) {
            this.sideMenuService.showSideMenu();
        } else {
            this.sideMenuService.hideSideMenu();
        }
    }

    closeSideMenu() {
        this.sideMenuService.hideSideMenu();
    }

    checkWidth() {
        if (window.innerWidth > MEDIUM_WIDTH_SIZE) {
            this.mobileView = false;
            this.sideMenuService.disableSideMenu();
            this.sideMenuService.hideSideMenu();
        } else {
            this.mobileView = true;
            this.sideMenuService.enableSideMenu();
        }
    }
}
