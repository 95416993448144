import {Injectable, TemplateRef} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogComponent} from './dialog.component';
import {first} from 'rxjs/operators';
import {isDefined} from '../../commons/commons';

export type DialogRef<T, R> = MatDialogRef<DialogComponent<T>, R>;

export interface DialogData<T> {
    title?: string;
    template: TemplateRef<any>;
    context?: T;
}

export interface DialogOptions {
    width?: number;
    hideCloseButton?: boolean;
}

export interface PrivateDialogData<T> extends DialogData<T> {
    hideCloseButton: boolean;
}

const DEFAULT_OPTIONS: DialogOptions = {
    hideCloseButton: false
};

/**
 * Class used to manage opened {@link DialogComponent} instances.
 */
export class Dialog<T = undefined, R = any> {

    constructor(private readonly dialogRef: DialogRef<T, R>) {
    }

    get opened(): Promise<void> {
        return this.dialogRef.afterOpened().pipe(first()).toPromise();
    }

    get closed(): Promise<R> {
        return this.dialogRef.afterClosed().pipe(first()).toPromise();
    }

    get context(): T {
        return this.dialogRef.componentInstance.data.context;
    }

    set context(context: T) {
        this.dialogRef.componentInstance.data.context = context;
    }

    get title(): string {
        return this.dialogRef.componentInstance.data.title;
    }

    set title(title: string) {
        this.dialogRef.componentInstance.data.title = title;
    }

    get template(): TemplateRef<any> {
        return this.dialogRef.componentInstance.data.template;
    }

    set template(template: TemplateRef<any>) {
        this.dialogRef.componentInstance.data.template = template;
    }

    close(result?: R) {
        this.dialogRef.close(result);
    }
}

/**
 * Service used to open modal {@link DialogComponent dialogs}.
 */
@Injectable()
export class DialogService {

    constructor(private readonly dialog: MatDialog) {
    }

    open<T, R = any>(data: DialogData<T>, options: DialogOptions = DEFAULT_OPTIONS): Dialog<T, R> {
        const dialogRef = this.dialog.open<DialogComponent<T>, PrivateDialogData<T>, R>(DialogComponent, {
            hasBackdrop: true,
            width: isDefined(options.width) ? `${options.width}px` : undefined,
            disableClose: options.hideCloseButton,
            data: {
                ...data,
                hideCloseButton: options.hideCloseButton
            }
        });
        return new Dialog(dialogRef);
    }
}
