// angular
import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// commons
import {CommonsConfig} from './commons.config';
import {
    AsClassPipe,
    AsIdPipe,
    FullnamePipe,
    MapSizePipe,
    MapToArrayPipe,
    NumberFormatPipe,
    SortArrayPipe
} from './commons.pipe';
import {
    ApplicationNameDirective,
    ApplicationVersionDirective,
    DisableControlDirective,
    FromYearDirective,
    MimeIconDirective,
    VarDirective
} from './commons.directive';
import {StorageService} from './storage.service';
import {I18nModule} from '../i18n/i18n.module';
import {Observable} from 'rxjs';

export interface CurrentView<D> {
    viewData: Observable<D>;
}

export const CURRENT_VIEW_TOKEN = new InjectionToken<CurrentView<unknown>>('current.view');

@NgModule({
    imports: [
        CommonModule,
        I18nModule
    ],
    declarations: [
        FullnamePipe,
        AsIdPipe,
        AsClassPipe,
        MapToArrayPipe,
        SortArrayPipe,
        MapSizePipe,
        NumberFormatPipe,
        ApplicationVersionDirective,
        ApplicationNameDirective,
        FromYearDirective,
        MimeIconDirective,
        DisableControlDirective,
        VarDirective
    ],
    exports: [
        FullnamePipe,
        AsIdPipe,
        AsClassPipe,
        MapToArrayPipe,
        SortArrayPipe,
        MapSizePipe,
        NumberFormatPipe,
        ApplicationVersionDirective,
        ApplicationNameDirective,
        FromYearDirective,
        MimeIconDirective,
        DisableControlDirective,
        VarDirective,
    ]
})
export class CommonsModule {

    /**
     * Configures the CommonsModule.
     *
     * @param config Module configuration
     * @return The module with the providers
     */
    static forRoot(config: CommonsConfig): ModuleWithProviders<CommonsModule> {
        return {
            ngModule: CommonsModule,
            providers: [
                {provide: CommonsConfig, useValue: config},
                {provide: CURRENT_VIEW_TOKEN, useValue: {viewData: null, path: null}},
                // services
                StorageService
            ]
        };
    }
}
