import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {BillOfMaterialsModule} from './bill-of-materials.module';
import {MsgBoxService} from './msg-box.service';
import {MomentDateModule} from '@angular/material-moment-adapter';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AgmCoreModule} from '@agm/core';
import {CommonsModule} from '../commons/commons.module';
import {I18nModule} from '../i18n/i18n.module';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {DATE_FORMATS} from '../i18n/i18n-defaults';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {IconComponent} from './icon/icon.component';
import {SharedConfig} from './shared.config';
import {DialogService} from './dialog/dialog.service';
import {DialogComponent} from './dialog/dialog.component';
import {SideMenuService} from './side-menu.service';
import {
    ECMemberEntitySubtypePipe,
    ECMemberJuridicalTypePipe,
    ECMemberLegalFormTypePipe,
    ECMemberSubjectTypePipe,
    GenericPipe,
    HasUtilityPlantPipe
} from './shared.pipe';
import {
    GoogleMapsAddressAutocompleteComponent
} from './google-maps-address-autocomplete/google-maps-address-autocomplete.component';

@NgModule({
    declarations: [
        IconComponent,
        DialogComponent,
        GoogleMapsAddressAutocompleteComponent,
        GenericPipe,
        HasUtilityPlantPipe,
        ECMemberJuridicalTypePipe,
        ECMemberLegalFormTypePipe,
        ECMemberSubjectTypePipe,
        ECMemberEntitySubtypePipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        BillOfMaterialsModule,
        FlexLayoutModule,
        FontAwesomeModule,
        MomentDateModule,
        FormsModule,
        ReactiveFormsModule,
        AgmCoreModule,
        // application
        CommonsModule,
        I18nModule
    ],
    exports: [
        // modules
        BillOfMaterialsModule,
        FlexLayoutModule,
        FontAwesomeModule,
        // components
        IconComponent,
        DialogComponent,
        GoogleMapsAddressAutocompleteComponent,
        GenericPipe,
        HasUtilityPlantPipe,
        ECMemberJuridicalTypePipe,
        ECMemberLegalFormTypePipe,
        ECMemberSubjectTypePipe,
        ECMemberEntitySubtypePipe
    ]
})
export class SharedModule {

    constructor(library: FaIconLibrary) {
        library.addIconPacks(fal, fas);  // FAL and FAS are the pro icons
    }

    /**
     * Configures the CommonsModule.
     *
     * @param config Module configuration
     * @return The module with the providers
     */
    static forRoot(config: SharedConfig): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                // services
                MsgBoxService,
                DialogService,
                SideMenuService,
                {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
                {provide: SharedConfig, useValue: config}
            ]
        };
    }
}
