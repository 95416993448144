import { Component, OnInit } from '@angular/core';
import {APP_CONFIG} from '../../app.config';

const incentiveParagraph = 'The incentive is based on the Premium Incentive Rate and Incentive Component Return, the latter defined by the value of the unit fee. ' +
    'Both are provided by the GSE and and valued respectively at 110€/MWh and 8€/MWh (approx). ' +
    'The Incentive Component Return is defined by ARERA and is calculated on the basis of the parameters that define grid charges established each year by the authority. ' +
    'The Premium Incentive Rate was defined by MiSe (now the responsibility of MASE). ' +
    'These values in euros must be multiplied by the shared energy to obtain the total incentive.';

const incentiveParagraph2 = 'The incentive is based on the Premium Incentive Rate and Incentive Component Return, but the latter depends on both the unit fee and the grid loss coefficient. ' +
    'Both are provided by the GSE and are valued respectively at 100€/MWh and 8€/MWh (approx). ' +
    'The Incentive Component Return is defined by ARERA and is calculated on the basis of the parameters that define grid charges established each year by the authority. ' +
    'The Premium Incentive Rate was defined by MiSe (now the responsibility of MASE). ' +
    'These values in euros must be multiplied by the shared energy to obtain the total incentive.';

const incentiveParagraph3 = 'This task is the responsibility of the referent, who must send the request exclusively electronically, by accessing the socalled GSE Computer Portal, ' +
    'authenticating in the <a href="https://areaclienti.gse.it" target="_blank" rel="noopener noreferrer">customer area</a> using the credentials (User ID and password) provided by ' +
    'the GSE during registration and then using the application "Production and Consumption Systems - SPC" present within the Portal. ' +
    'Then always the referent is required to complete a series of documents necessary for the GSE, ' +
    'so in the end it will be possible to draw up the final contract between the parties.';

const incentiveParagraph4 = 'Once the bureaucratic process with the GSE has been completed, ' +
    'the effective date is the date on which the application was submitted to the GSE; ' +
    'therefore, the GSE retroactively calculates the arrears accrued during the period of bureaucratic fulfillment. ' +
    'Alternatively, another date agreed upon between contact person and GSE can be decided.';

const incentiveParagraph5 = 'Electricity production plant powered by renewable sources is defined as an electricity production plant that exclusively uses wind, solar, aerothermal, geothermal, hydrothermal and ocean, hydraulic, biomass, landfill gas, residual gas from purification processes and biogas.\n ' +
    'The only permitted interventions are those of new plants construction or existing plants upgrades, ' +
    'in which case only the added plant section is taken into account in the configuration. ' +
    'With the creation of the "Testo Integrato per l\'Autoconsumo Diffuso (TIAD)", the possibility of adhesion for existing plants will be introduced, ' +
    'given their renewable electricity production, but not exceeding 30 percent of the total power that community generates.';

const SHOW_BREADCRUMB = APP_CONFIG.showBreadCrumb;

@Component({
  selector: 'app-application-incentives',
  templateUrl: './application-incentives.component.html',
  styleUrls: ['./application-incentives.component.scss']
})
export class ApplicationIncentivesComponent implements OnInit {

  // Exported in HTML
  INCENTIVE_REC = incentiveParagraph;
  INCENTIVE_SCG = incentiveParagraph2;
  INCENTIVE_ASK = incentiveParagraph3;
  INCENTIVE_START = incentiveParagraph4;
  WHICH_PLANTS = incentiveParagraph5;
  SHOW_BREADCRUMB = SHOW_BREADCRUMB;

  constructor() { }

  ngOnInit(): void {
  }

}
