<div *ngIf="!data.hideCloseButton" class="close-button secondary-text">
    <button mat-icon-button (click)="close()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </button>
</div>
<div mat-dialog-content app-dialog-content>
    <div *ngIf="data.title" app-dialog-title>
        {{data.title}}
    </div>
    <ng-container [ngTemplateOutlet]="data.template"
                  [ngTemplateOutletContext]="context">
    </ng-container>
</div>
