import {Component, HostListener, OnInit} from '@angular/core';
import {Pills} from '../../commons/commons.config';
import {APP_CONFIG} from '../../app.config';
import {Router} from '@angular/router';

const participateParagraph = 'If you want be a part of an energy community, send us your request.';
const participatePt2Paragraph = 'A community may be waiting for you!';
const suggestParagraph = 'If you are thinking about <b>joining a Renewable Energy Community</b>, we suggest you read the following topics:';
const NUM_OF_COLS = 3;
const LARGE_WIDTH_SIZE = 1279; // lt-lg
const RAPPORT = '44:21';
const SHOW_BREADCRUMB = APP_CONFIG.showBreadCrumb;

interface ExtendedPills extends Pills {
    cols: number;  // number of columns pills occupy in the grid
}

@Component({
    selector: 'app-application-pills',
    templateUrl: './application-pills.component.html',
    styleUrls: ['./application-pills.component.scss']
})
export class ApplicationPillsComponent implements OnInit {

    pills: ExtendedPills[];
    numOfCols: number;
    rapport: string;

    // Exported in HTML
    PARTICIPATE = participateParagraph;
    PARTICIPATE_PT2 = participatePt2Paragraph;
    SUGGEST = suggestParagraph;
    SHOW_BREADCRUMB = SHOW_BREADCRUMB;

    constructor(private readonly router: Router) {
        this.pills = [];
        this.numOfCols = NUM_OF_COLS;
        this.rapport = RAPPORT;
    }

    ngOnInit() {
        this.initializeCols();
        this.checkWidth();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkWidth();
    }

    checkWidth() {
        if (window.innerWidth > LARGE_WIDTH_SIZE) {
            this.changeCols(1);
            this.rapport = RAPPORT;
        } else {
            this.changeCols(3);
            this.rapport = '6:10';
        }
    }

    initializeCols() {
        const arr = APP_CONFIG.pills;
        for (const pill of arr) {
            this.pills.push({
                id: pill.id,
                title: pill.title,
                subtitle: pill.subtitle,
                background: pill.background,
                backgroundHeading: pill.backgroundHeading,
                cols: 1
            } as ExtendedPills);
        }
    }

    changeCols(ColsNum: number) {
        for (const pill of this.pills) {
            pill.cols = ColsNum;
        }
    }
}
