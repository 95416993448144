import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class SideMenuService {
    private readonly showSideMenuSubj: BehaviorSubject<boolean>;
    private readonly sideMenuEnabledSubj: BehaviorSubject<boolean>;

    constructor() {
        this.showSideMenuSubj = new BehaviorSubject<boolean>(false);
        this.sideMenuEnabledSubj = new BehaviorSubject<boolean>(false);
    }

    get sideMenuShowStatus(): Observable<boolean> {
        return this.showSideMenuSubj;
    }

    get sideMenuEnabledStatus(): Observable<boolean> {
        return this.sideMenuEnabledSubj;
    }

    showSideMenu() {
        this.showSideMenuSubj.next(true);
    }

    hideSideMenu() {
        this.showSideMenuSubj.next(false);
    }

    toggleSideMenu() {
        this.showSideMenuSubj.next(!this.showSideMenuSubj.value);
    }

    enableSideMenu() {
        this.sideMenuEnabledSubj.next(true);
    }

    disableSideMenu() {
        this.sideMenuEnabledSubj.next(false);
    }
}
