import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {isDefined} from '../../commons/commons';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {clearErrors, validateFloat, validateInteger, validateNotBlank} from '../../commons/validators';
import {
    EMAIL_PATTERN,
    FISCAL_CODE_PATTERN,
    MOBILE_PATTERN,
    NAME_PATTERN,
    POD_PATTERN,
    PROVIDER_PATTERN,
    VAT_NUMBER_PATTERN
} from '../../shared/patterns';
import {ApiService} from '../../api/api.service';
import {debounceTime, map, startWith, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, merge, Observable, of, Subject, Subscription} from 'rxjs';
import {I18NService} from '../../i18n/i18n.service';
import {MsgBoxService} from '../../shared/msg-box.service';
import {DocConfig, NionMessage} from '../../api/dto';
import {
    DEFAULT_MAX_CHARS,
    EMAIL_MAX_CHARS,
    MOBILE_MAX_CHARS,
    NAME_MAX_CHARS,
    POD_MAX_CHARS,
    PROFILATIONS_MAX_CHARS,
    TAX_ID_MAX_CHARS,
    TOTAL_CONSUMPTION_MAX_CHARS,
    VAT_NUMBER_MAX_CHARS
} from '../../shared/limits';
import {SpinnerData} from '../../shared/spinner/spinner.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {Router} from '@angular/router';
import {ECMemberLegalFormTypePipe, ECMemberSubjectTypePipe, GenericPipe} from '../../shared/shared.pipe';
import {APP_CONFIG} from '../../app.config';
import {
    EC_DOCUMENT_CF,
    EC_DOCUMENT_CI,
    ECEntitySubtype,
    ECJuridicalType,
    ECLegalForm,
    ECMemberAddress,
    ECMemberType,
    ECPodUtilityType,
    ECSubjectType,
    SpatialCoordinates
} from 'smag-commons/cfg/hierarchy';
import {
    AllFieldTypes,
    ConfigurabilityFieldsFrontEnd,
    HasUtilityPlant,
    JURIDICAL_POD_UTILITY_TYPES,
    PHYSICAL_POD_UTILITY_TYPES,
    PreMemberCommunity,
    PreMemberDocument,
    PreMemberProfiling,
    PreMemberRequest,
    PreMemberUtility,
    UtilitiesClassConsRanges,
    UtilityProductionType
} from 'pms-commons/premember';
import {Address, googleZoneMap} from '../../shared/google-maps-address-autocomplete/google-maps-address-autocomplete.component';
import {DEFAULT_TIMEZONE} from '../../i18n/i18n-defaults';
import * as moment from 'moment-timezone';
import {Moment} from 'moment-timezone';


interface MimeType {
    name: string;
    type: string;
}

interface TmpDocument {
    fileName: string;
    file: File;
}

interface PodCustomAddressEnabled {
    pod: string;
    enabled: boolean;
}

const PERSONAL_DATA = 'Personal data';
const YOUR_UTILITIES = 'Your utilities';
const ENERGY_PROFILING = 'Energy profiling';
const ACCEPT_POLICY = 'Accept Policy';

/* Steps will comprehend the current existing steps, taken from the backend configuration;
*  the missing fields are going to be managed somewhere else
*/
// tslint:disable-next-line:prefer-const
const STEPS: string[] = [
    PERSONAL_DATA, YOUR_UTILITIES, ENERGY_PROFILING, ACCEPT_POLICY
];

interface ConfigFieldsContainer {
    stepPersonalData: {
        subject_type: boolean;  // this includes juridical type, legal form, subject type, entity subtype
        first_name: boolean;
        last_name: boolean;
        company_name: boolean;
        address: boolean;
        custom_address: boolean;
        tax_id: boolean;
        email: boolean;
        pec: boolean;
        piva: boolean;
        mobile_number: boolean;
    };
    stepUtilities: {
        pod: boolean;
        pod_utility_type: boolean;
        pod_address: boolean;
        pod_custom_address: boolean;
        wifi: boolean;
        has_utility_plant: boolean;
        utility_production_type: boolean;
        power: boolean;
        pv_plant_effective_date: boolean;
        provider: boolean;
    };
}

const MAX_FILE_MB = 2;
const FILE_MIME_TYPES: MimeType[] = [
    {
        type: 'application/pdf',
        name: 'PDF'
    },
    {
        type: 'image/jpeg',
        name: 'JPG'
    },
    {
        type: 'image/png',
        name: 'PNG'
    }
];

const DIALOG_WIDTH = 700;
const SHOW_BREADCRUMB = APP_CONFIG.showBreadCrumb;
const PROSPECTUS_TITLE = APP_CONFIG.prospectusTitle;
const PROSPECTUS_BODY = APP_CONFIG.prospectusBody;
const CONDOMINIUM = 'condominium';
const SME_FACTORY = 'sme_factory';
const RESIDENTIAL = 'residential';

const LEGAL_FORM_TYPES: ECLegalForm[] = [
    ECLegalForm.ENTREPRENEUR_SOLE_PROPRIETORSHIP_AGRICULTURAL_ENTERPRISE,
    ECLegalForm.OTHER_INDIVIDUALS
];

const SUBJECT_TYPES: ECSubjectType[] = [
    ECSubjectType.TERRITORIAL_BODY_LOCAL_AUTHORITY_OTHER_BODY,
    ECSubjectType.PMI,
    ECSubjectType.ASSOCIATION_WITH_LEGAL_PERSONALITY_UNDER_PRIVATE_LAW,
    ECSubjectType.ASSOCIATED_FIRM_COMPANY_OF_PROFESSIONALS,
    ECSubjectType.OTHER,
    // ECSubjectType.COMMUNITY  // unnecessary with members
];

const ENTITY_SUBTYPES: ECEntitySubtype[] = [
    ECEntitySubtype.STATE,
    ECEntitySubtype.REGION,
    ECEntitySubtype.PROVINCE,
    ECEntitySubtype.MUNICIPALITY,
    ECEntitySubtype.MUNICIPALITIES_UNION,
    ECEntitySubtype.MOUNTAIN_COMMUNITY,
    ECEntitySubtype.ISLAND_COMMUNITY,
    ECEntitySubtype.METROPOLITAN_CITY,
    ECEntitySubtype.RESEARCH_AND_TRAINING_ORGANIZATION,
    ECEntitySubtype.RELIGIOUS_ORGANIZATION,
    ECEntitySubtype.THIRD_SECTOR_ORGANIZATION,
    ECEntitySubtype.ENVIRONMENT_PREVENTION_ORGANIZATION,
    ECEntitySubtype.LOCAL_ADMINISTRATION_ORGANIZATION
];


@Component({
    selector: 'app-application-form',
    templateUrl: './application-form.component.html',
    styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit, OnDestroy {
    private destroyed = new Subject<void>();
    private pipe = new GenericPipe(this.i18n);
    private legalFormPipe = new ECMemberLegalFormTypePipe();
    private subjectTypePipe = new ECMemberSubjectTypePipe();
    private yearlyConsSubscription: Subscription = null;
    private presentFields: AllFieldTypes[] = null;
    private tmpDocuments: { [id: string]: TmpDocument } = {};
    private selectedJuridicalType: ECJuridicalType = ECJuridicalType.INDIVIDUAL;
    private podsIds: string[];
    private memberCoordinates: SpatialCoordinates = null;
    private temp: { coordinates: SpatialCoordinates, zone: string, region: string } = null;
    private turboMaxHeight: number;
    private podCustomAddressEnabled: BehaviorSubject<PodCustomAddressEnabled> = new BehaviorSubject({pod: "1", enabled: false});
    @ViewChild('spinner') private spinnerDialog: TemplateRef<any>;
    @ViewChild('setPlantDate') private setPlantDateDialog: TemplateRef<any>;
    personalDataForm: FormGroup;
    utilitiesForm: FormGroup;
    profilationsForm: FormGroup;
    acceptanceForm: FormGroup;
    currentStep: number = 0;
    docTypes: DocConfig[];
    pods: string[] = [];
    ecmLinked: boolean = false;
    instanceCommunities: PreMemberCommunity[] | null;
    privacyPolicyLink: string = null;
    configurabilitySteps: ConfigFieldsContainer;
    STEPS: string[] = [];
    customAddressEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
    podsCustomAddressEnabled: { [pod: string]: boolean } = {};

    // Exported in HTML
    ACCEPTED_MIME_TYPES = FILE_MIME_TYPES.map(mt => mt.type).join(',');
    MAX_FILE_MB = MAX_FILE_MB;
    ECJuridicalType = ECJuridicalType;
    HasUtilityPlant = HasUtilityPlant;
    ECSubjectType = ECSubjectType;
    UtilityProductionType = UtilityProductionType;
    UtilitiesClassConsRanges = UtilitiesClassConsRanges;
    DEFAULT_MAX_CHARS = DEFAULT_MAX_CHARS;
    POD_MAX_CHARS = POD_MAX_CHARS;
    NAME_MAX_CHARS = NAME_MAX_CHARS;
    EMAIL_MAX_CHARS = EMAIL_MAX_CHARS;
    MOBILE_MAX_CHARS = MOBILE_MAX_CHARS;
    TAX_ID_MAX_CHARS = TAX_ID_MAX_CHARS;
    TOTAL_CONSUMPTION_MAX_CHARS = TOTAL_CONSUMPTION_MAX_CHARS;
    PROFILATIONS_MAX_CHARS = PROFILATIONS_MAX_CHARS;
    VAT_NUMBER_MAX_CHARS = VAT_NUMBER_MAX_CHARS;
    SHOW_BREADCRUMB = SHOW_BREADCRUMB;
    PROSPECTUS_BODY = PROSPECTUS_BODY;
    PROSPECTUS_TITLE = PROSPECTUS_TITLE;
    CONDOMINIUM = CONDOMINIUM;
    SME_FACTORY = SME_FACTORY;
    RESIDENTIAL = RESIDENTIAL;
    legalFormTypes: ECLegalForm[] = LEGAL_FORM_TYPES;
    subjectTypes: ECSubjectType[] = SUBJECT_TYPES;
    entitySubtypes: ECEntitySubtype[] = ENTITY_SUBTYPES;
    // Manage steps configurability in HTML
    PERSONAL_DATA = PERSONAL_DATA;
    YOUR_UTILITIES = YOUR_UTILITIES;
    ENERGY_PROFILING = ENERGY_PROFILING;
    ACCEPT_POLICY = ACCEPT_POLICY;
    isDefined = isDefined;
    protected readonly top = top;


    constructor(private fb: FormBuilder,
                private api: ApiService,
                private readonly msgBoxService: MsgBoxService,
                private readonly i18n: I18NService,
                private readonly dialogService: DialogService,
                private readonly router: Router) {
    }

    ngOnInit() {
        this.getDocTypes().subscribe(docTypes => this.docTypes = docTypes);

        combineLatest([this.getFieldsConfigurability(), this.isLinkedToEcm(), this.getInstanceCommunities()])
            .pipe(takeUntil(this.destroyed))
            .subscribe(([fieldsCfg, isLinked, communities]) => {
                this.presentFields = fieldsCfg.present_fields;
                this.ecmLinked = isLinked;
                this.instanceCommunities = communities; // null if not configured

                this.setMissingFieldsConfigurability();
                const temp = fieldsCfg.is_energy_profiling_step_missing ?
                    STEPS.filter(step => step !== ENERGY_PROFILING) :
                    STEPS;
                temp.forEach(t => this.STEPS.push(t));
                for (const step of this.STEPS) {
                    switch (step) {
                        case PERSONAL_DATA:
                            this.personalDataForm = this.fb.group({
                                juridicalType: new FormControl(ECJuridicalType.INDIVIDUAL,
                                    this.configurabilitySteps.stepPersonalData.subject_type ? [Validators.required] : []),
                                legalForm: new FormControl(null, []),
                                subjectType: new FormControl(null, []),
                                entitySubtype: new FormControl(null, []),
                                firstName: new FormControl(null,
                                    this.configurabilitySteps.stepPersonalData.first_name ? [validateNotBlank, Validators.pattern(NAME_PATTERN)] : []),
                                lastName: new FormControl(null,
                                    this.configurabilitySteps.stepPersonalData.last_name ? [validateNotBlank, Validators.pattern(NAME_PATTERN)] : []),
                                address: new FormControl(null,
                                    this.configurabilitySteps.stepPersonalData.address ? [Validators.required] : []),
                                custom_address: new FormControl(null, this.configurabilitySteps.stepPersonalData.custom_address &&
                                this.customAddressEnabled.getValue() ? [Validators.required, validateNotBlank] : []),
                                email: new FormControl(null,
                                    this.configurabilitySteps.stepPersonalData.email ? [validateNotBlank, Validators.pattern(EMAIL_PATTERN)] : []),
                                confirmEmail: new FormControl(null,
                                    this.configurabilitySteps.stepPersonalData.email ? [validateNotBlank, Validators.pattern(EMAIL_PATTERN)] : []),
                                pec: new FormControl(null,
                                    this.configurabilitySteps.stepPersonalData.pec ? [Validators.pattern(EMAIL_PATTERN)] : []),
                                mobile: new FormControl(null,
                                    this.configurabilitySteps.stepPersonalData.mobile_number ? [validateNotBlank, Validators.pattern(MOBILE_PATTERN)] : []),
                                companyName: new FormControl(null,
                                    this.configurabilitySteps.stepPersonalData.company_name ? [validateNotBlank] : []),
                                vatNumber: new FormControl(null,
                                    this.configurabilitySteps.stepPersonalData.piva ? [validateNotBlank, Validators.pattern(VAT_NUMBER_PATTERN)] : []),
                                taxCode: new FormControl(null,
                                    this.configurabilitySteps.stepPersonalData.tax_id ? [validateNotBlank, Validators.pattern(FISCAL_CODE_PATTERN)] : []),
                                preferredCommunity: new FormControl(null, [])
                            }, {
                                validators: ctrl => this.validateForm(ctrl)
                            });
                            if (this.configurabilitySteps.stepPersonalData.custom_address) {
                                this.customAddressEnabled.asObservable().pipe(takeUntil(this.destroyed)).subscribe(enabled => {
                                    if (enabled) {
                                        this.personalDataForm.controls.custom_address.setValidators([validateNotBlank, Validators.required]);
                                    } else {
                                        this.personalDataForm.controls.custom_address.clearValidators();
                                        this.personalDataForm.controls.custom_address.setValue(null);
                                    }
                                    this.personalDataForm.controls.custom_address.updateValueAndValidity();
                                    this.updateLimitHeight();
                                })
                            }
                            break;
                        case YOUR_UTILITIES:
                            this.utilitiesForm = this.fb.group({});
                            break;
                        case ENERGY_PROFILING:
                            this.profilationsForm = this.fb.group({});
                            break;
                        case ACCEPT_POLICY:
                            this.acceptanceForm = this.fb.group({
                                policy: new FormControl(null, [Validators.requiredTrue])
                            });
                            break;
                    }
                }
                this.addAnotherPod();

                // listen to subject juridical type value changes
                this.personalDataForm.controls.juridicalType.valueChanges.pipe(
                    startWith(this.personalDataForm.controls.juridicalType.value),  // force start
                    takeUntil(this.destroyed)
                ).subscribe(selectedJuridicalType => {
                    this.selectedJuridicalType = selectedJuridicalType;
                    this.personalDataForm.controls.subjectType.reset();
                    this.onChangeSubjectType(selectedJuridicalType);
                    this.onChangeResetPodsVals();
                });

                // listen to subject type value changes
                this.personalDataForm.controls.subjectType.valueChanges
                    .pipe(takeUntil(this.destroyed))
                    .subscribe(selectedSubjType => {
                        if (selectedSubjType === ECSubjectType.TERRITORIAL_BODY_LOCAL_AUTHORITY_OTHER_BODY) {
                            this.personalDataForm.controls.entitySubtype.setValidators([Validators.required]);
                        } else {
                            this.personalDataForm.controls.entitySubtype.clearValidators();
                            this.personalDataForm.controls.entitySubtype.updateValueAndValidity();
                        }
                        this.onChangeSubjectType(this.selectedJuridicalType);
                        this.onChangeResetPodsVals();
                    });

                // listen to legal form value change to reset: company name, tax code and vat number
                this.personalDataForm.controls.legalForm.valueChanges
                    .pipe(takeUntil(this.destroyed))
                    .subscribe(() => {
                        this.personalDataForm.controls.companyName.reset();
                        this.personalDataForm.controls.taxCode.reset();
                        this.personalDataForm.controls.vatNumber.reset();
                    });

                this.personalDataForm.controls.entitySubtype.valueChanges.pipe(
                    takeUntil(this.destroyed))
                    .subscribe(() => this.onChangeResetPodsVals());

                if (communities) { // add neutral obj to arr
                    this.instanceCommunities.unshift({
                        id: '0',
                        name: 'No preference'
                    });
                    this.personalDataForm.controls.preferredCommunity.setValue(this.instanceCommunities[0]);
                }
            });

        this.getPrivacyPolicyLink().subscribe(link => this.privacyPolicyLink = link);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    get addressFormControl(): FormControl {
        return this.personalDataForm.controls.address as FormControl;
    }

    get customAddressFormControl(): FormControl {
        return this.personalDataForm.controls.custom_address as FormControl;
    }

    get mimeTypesNames(): string {
        return FILE_MIME_TYPES.map(mt => mt.name).join(', ');
    }

    get isPersonalDataPageValid(): boolean {
        let valid =
            this.personalDataForm.controls.juridicalType.valid &&
            this.personalDataForm.controls.legalForm.valid &&
            this.personalDataForm.controls.subjectType.valid &&
            this.personalDataForm.controls.entitySubtype.valid &&
            this.personalDataForm.controls.firstName.valid &&
            this.personalDataForm.controls.lastName.valid &&
            this.personalDataForm.controls.address.valid &&
            this.personalDataForm.controls.email.valid &&
            this.personalDataForm.controls.confirmEmail.valid &&
            this.personalDataForm.controls.pec.valid &&
            this.personalDataForm.controls.mobile.valid &&
            (this.customAddressEnabled.getValue() ? this.personalDataForm.controls.custom_address.valid : true) &&
            this.validateDocuments();
        if (this.isCompany) {
            valid = valid && this.personalDataForm.controls.companyName.valid &&
                this.personalDataForm.controls.vatNumber.valid;
        }
        if (!this.isCompany) {
            valid = valid && this.personalDataForm.controls.taxCode.valid;
        }
        // Form debugging (you can copy and paste into other form controls -change formName- to check them)
        // console.debug('form is valid --> ', this.personalDataForm.valid);
        // Object.keys(this.personalDataForm.controls).forEach(key => {
        //     const control = this.personalDataForm.get(key);
        //     console.log('Controllo:', key, ', Valore:', control.value, ', Validità:', control.valid);
        // });
        return valid;
    }

    get isYourUtilitiesPageValid(): boolean {
        let valid = true;
        this.pods.forEach(pod => {
            valid = valid &&
                this.utilitiesForm.controls[`pod_${pod}_code`].valid &&
                this.utilitiesForm.controls[`pod_${pod}_type`].valid &&
                this.utilitiesForm.controls[`pod_${pod}_wifi`].valid &&
                this.utilitiesForm.controls[`pod_${pod}_utility_type`].valid &&
                this.utilitiesForm.controls[`pod_${pod}_provider`].valid;
            if (this.utilitiesForm.controls[`pod_${pod}_utility_type`].value === HasUtilityPlant.YES) {
                valid = valid &&
                    this.utilitiesForm.controls[`pod_${pod}_plant_type`].valid &&
                    this.utilitiesForm.controls[`pod_${pod}_power`].valid &&
                    !this.utilitiesForm.controls[`pod_${pod}_pv_plant_effective_date`].invalid;
            }
            if (!this.addressPodFormControl(pod).disabled) {
                valid = valid && this.utilitiesForm.controls[`pod_${pod}_address`].valid;
            }
            if (!this.customAddressPodFormControl(pod).disabled) {
                valid = valid
                    && (this.podsCustomAddressEnabled[pod] ? this.utilitiesForm.controls[`pod_${pod}_custom_address`].valid : true);
            }
        });
        return valid;
    }

    get isEnergyProfilingPageValid(): boolean {
        let valid = true;
        this.pods.forEach(pod => {
            valid = valid && this.profilationsForm.controls[`pod_${pod}_yearly_consumption`].valid;
            if (!this.isCompany) {
                valid = valid &&
                    this.profilationsForm.controls[`pod_${pod}_people`].valid &&
                    this.profilationsForm.controls[`pod_${pod}_tv`].valid &&
                    this.profilationsForm.controls[`pod_${pod}_pc`].valid &&
                    this.profilationsForm.controls[`pod_${pod}_ac`].valid &&
                    this.profilationsForm.controls[`pod_${pod}_dishwasher`].valid;
            }
        });
        return valid;
    }

    get isPolicyValid(): boolean {
        return this.acceptanceForm.controls.policy.valid;
    }

    // This gives you the max needed height to let divs fall into the fxLayout "column wrap" without creating a third column
    get limitColumnHeight(): string {
        const len: number = Math.floor(this.turboMaxHeight / 2) + this.turboMaxHeight % 2;
        return ((100 * len) + 152 + (this.customAddressEnabled.getValue() ? 20 : 0)).toString();
    }

    get isCompany(): boolean {
        return !(this.selectedJuridicalType === ECJuridicalType.INDIVIDUAL &&
            (this.personalDataForm?.controls.legalForm.value === ECLegalForm.OTHER_INDIVIDUALS ||
                this.personalDataForm?.controls.legalForm.value !== ECLegalForm.ENTREPRENEUR_SOLE_PROPRIETORSHIP_AGRICULTURAL_ENTERPRISE));
    }

    updateYearlyConsumption(util: PreMemberUtility, podId: string) {
        this.api.updateYearlyConsumption(util)
            .then(yearlyCons => this.profilationsForm.controls['pod_' + podId + '_estimated_yearly_consumption'].setValue(yearlyCons));
    }

    getDocTypes(): Observable<DocConfig[]> {
        return this.api.getDocTypes();
    }

    getFieldsConfigurability(): Observable<ConfigurabilityFieldsFrontEnd> {
        return this.api.getFieldsConfigurability();
    }

    isLinkedToEcm(): Observable<boolean> {
        return this.api.isLinkedToEcm();
    }

    getPrivacyPolicyLink(): Observable<string> {
        return this.api.getPrivacyPolicyLink();
    }

    getInstanceCommunities(): Observable<PreMemberCommunity[] | null> {
        return this.api.getInstanceCommunities();
    }

    addressPodFormControl(podId: string): FormControl {
        return this.utilitiesForm.controls['pod_' + podId + '_address'] as FormControl;
    }

    customAddressPodFormControl(podId: string): FormControl {
        return this.utilitiesForm.controls['pod_' + podId + '_custom_address'] as FormControl;
    }

    // This isn't actually used on the form, internal use only
    coordinatesPodFormControl(podId: string): FormControl {
        return this.utilitiesForm.controls['pod_' + podId + '_coordinates'] as FormControl;
    }

    // This isn't actually used on the form, internal use only
    zonePodFormControl(podId: string): FormControl {
        return this.utilitiesForm.controls['pod_' + podId + '_zone'] as FormControl;
    }

    secondPartPodValue(podId: string): boolean {
        return this.profilationsForm.controls['pod_' + podId + '_second_part'].value;
    }

    estimatedYearlyConsForPod(podId: string): string {
        return this.profilationsForm.controls['pod_' + podId + '_estimated_yearly_consumption'].value || '0';
    }

    addressChange($event: Address | null, podId?: string) {
        const address = $event ? {
            street: $event.street,
            street_number: $event.streetNumber,
            city: $event.municipality,
            postal_code: $event.postalCode,
            province: $event.province
        } : null;
        if (!podId) {
            this.addressFormControl.setValue(address);
            this.memberCoordinates = $event ? $event.coordinates : null;
            // We temporarily set coordinates in case we want to copy them
            this.temp = {
                coordinates: $event.coordinates || null,
                zone: this.getZone($event.region || null),
                region: $event.region || null
            };
        } else {  // address change for pod
            this.addressPodFormControl(podId).setValue(address);
            this.coordinatesPodFormControl(podId).setValue($event.coordinates || null);
            this.zonePodFormControl(podId).setValue(this.getZone($event.region || null));
        }
    }

    isNextEnabled(stepName: string) {  // Help me stepName, I'm stuck
        switch (stepName) {
            case PERSONAL_DATA:
                return this.isPersonalDataPageValid;
            case YOUR_UTILITIES:
                return this.isYourUtilitiesPageValid;
            case ENERGY_PROFILING:
                return this.isEnergyProfilingPageValid;
            default:
                return false;
        }
    }

    isControlOK(controlName: string, form: FormGroup) {
        return form.controls[controlName].dirty && !form.controls[controlName].valid;
    }

    getPodError(controlName: string): string {
        let err = null;
        if (this.utilitiesForm.controls[controlName].dirty && this.utilitiesForm.controls[controlName].hasError('unique_pod')) {
            err = 'unique_pod';
        } else if (this.utilitiesForm.controls[controlName].dirty && this.utilitiesForm.controls[controlName].hasError('duplicate_pods')) {
            err = 'duplicate_pods';
        } else if (this.utilitiesForm.controls[controlName].dirty && !this.utilitiesForm.controls[controlName].valid) {
            err = 'invalid';
        }
        return err;
    }

    getPodCode(podId: string) {
        return this.utilitiesForm.controls['pod_' + podId + '_code'].value;
    }

    nextStep(target: HTMLElement) {
        this.currentStep++;
        target.scrollIntoView();
    }

    prevStep(target: HTMLElement) {
        this.currentStep--;
        target.scrollIntoView();
    }

    onClickRemoveDocument(doc: string) {
        delete this.tmpDocuments[doc];
    }

    onUploadDocumentFile(docId: string, event: Event) {
        const files = 'files';
        if (event.target[files] && event.target[files].length) {
            const file: File = event.target[files][0];
            if (this.validateFile(file)) {
                this.tmpDocuments[docId] = {
                    fileName: file.name,
                    file
                };
            }
        } else {
            this.tmpDocuments[docId] = {
                fileName: null,
                file: null
            };
        }
    }

    uploadedFileName(docId: string): string {
        return this.tmpDocuments[docId]?.fileName;
    }

    addAnotherPod() {
        const newPodId = this.generatePODId();
        this.pods.push(newPodId);
        this.addPodControls(newPodId);
        this.managePodAddressLikeMemberChange(newPodId);
        this.podsCustomAddressEnabled[newPodId] = false;
        if (this.STEPS.includes(ENERGY_PROFILING)) {  // The following are only needed if the profilation step exists
            this.managePodTypeChange(newPodId);
            this.manageYearlyConsumptionFetching();
        }
    }

    onClickRemovePOD(podId: string) {
        this.pods.splice(this.pods.indexOf(podId), 1);
        this.utilitiesForm.removeControl(`pod_${podId}_code`);
        this.utilitiesForm.removeControl(`pod_${podId}_type`);
        this.utilitiesForm.removeControl(`pod_${podId}_address`);
        this.utilitiesForm.removeControl(`pod_${podId}_custom_address`);
        this.utilitiesForm.removeControl(`pod_${podId}_wifi`);
        this.utilitiesForm.removeControl(`pod_${podId}_provider`);
        this.utilitiesForm.removeControl(`pod_${podId}_utility_type`);
        this.utilitiesForm.removeControl(`pod_${podId}_plant_type`);
        this.utilitiesForm.removeControl(`pod_${podId}_power`);
        this.utilitiesForm.removeControl(`pod_${podId}_pv_plant_effective_date`);
        this.utilitiesForm.removeControl(`pod_${podId}_coordinates`);
        this.utilitiesForm.removeControl(`pod_${podId}_zone`);
        this.utilitiesForm.removeControl(`pod_${podId}_address_like_member`);
        if (this.STEPS.includes(ENERGY_PROFILING)) {
            this.profilationsForm.removeControl(`pod_${podId}_people`);
            this.profilationsForm.removeControl(`pod_${podId}_tv`);
            this.profilationsForm.removeControl(`pod_${podId}_pc`);
            this.profilationsForm.removeControl(`pod_${podId}_ac`);
            this.profilationsForm.removeControl(`pod_${podId}_dishwasher`);
            this.profilationsForm.removeControl(`pod_${podId}_heater`);
            this.profilationsForm.removeControl(`pod_${podId}_custom_range`);
            this.profilationsForm.removeControl(`pod_${podId}_yearly_consumption`);
            this.profilationsForm.removeControl(`pod_${podId}_estimated_yearly_consumption`);
            this.profilationsForm.removeControl(`pod_${podId}_second_part`);
        }
    }

    sendIt() {
        const spinner = this.openSpinner('Sending request');
        const formData = new FormData();
        const req: PreMemberRequest = this.getFormData();
        formData.append('body', JSON.stringify(req));
        this.docTypes.filter(d => req.personal_data.docs.map(dc => dc.document_type).includes(d.name)).forEach(doc => {
            if (this.tmpDocuments[doc.name]) {
                formData.append(`file_${doc.name}`, this.tmpDocuments[doc.name].file, this.tmpDocuments[doc.name].fileName);
            }
        });
        this.api.sendApplicationRequest(formData)
            .then(() => {
                this.api.sendEmailRecap(this.mkEmailRecap(req)).then(() => {
                    spinner.context.next({
                        loading: false,
                        loadingMessage: this.i18n.translate('Request sent')
                    });
                    this.currentStep = 0;
                    this.resetAllForms();
                    this.tmpDocuments = {};
                    this.router.navigate(['/c', 'application']).then();
                });
            }).catch(err => {
            const errMsg = this.i18n.translate('An error occurred while sending the request');
            spinner.context.next({
                loading: false,
                loadingMessage: errMsg,
                abort: true
            });
            this.msgBoxService.error(errMsg, err);
        });
    }

    resetAllForms() {
        if (this.personalDataForm) {
            this.personalDataForm.reset();
            this.personalDataForm.controls.subjectType.setValue(ECMemberType.NATURAL_PERSON);
            if (this.instanceCommunities) {
                this.personalDataForm.controls.preferredCommunity.setValue(this.instanceCommunities[0]);
            }
        }
        if (this.utilitiesForm) {
            this.utilitiesForm.reset();
            this.pods.forEach(podId => {
                this.addPodControls(podId);
            });
        }
        if (this.profilationsForm) {
            this.profilationsForm.reset();
        }
        if (this.acceptanceForm) {
            this.acceptanceForm.reset();
        }
    }

    translateRangeFor(range: string): string {
        const startIndex = range.search(/[a-zA-Z]/);
        if (startIndex === -1) {
            return range;
        }
        return range.substring(0, startIndex) + this.i18n.translate(range.substring(startIndex));
    }

    dontHideFirstOption(podId: string): boolean {
        return this.ecmLinked &&
            (this.utilitiesForm.controls['pod_' + podId + '_type'].value !== ECPodUtilityType.RELIGIOUS_INSTITUTION &&
                this.utilitiesForm.controls['pod_' + podId + '_type'].value !== ECPodUtilityType.THIRD_SECTOR_ORGANIZATION_OR_ENVIRONMENT_PROTECTION_ORGANIZATION &&
                this.utilitiesForm.controls['pod_' + podId + '_type'].value !== ECPodUtilityType.OTHER);
    }

    getDocName(docCode: string): string {
        switch (docCode) {
            case EC_DOCUMENT_CI:
                return 'Identity document';
            case EC_DOCUMENT_CF:
                return 'Fiscal code';
            default:
                return 'Document';
        }
    }

    isThisPresent(key: AllFieldTypes): boolean {
        return this.presentFields?.includes(key);
    }

    plantHasDate(pod: string): boolean {
        return !!this.utilitiesForm.controls['pod_' + pod + '_pv_plant_effective_date'].value;
    }

    onClickResetPlantDate(pod: string) {
        this.utilitiesForm.controls['pod_' + pod + '_pv_plant_effective_date'].setValue(null);
    }

    onClickSetPlantDate(pod: string) {
        const today = moment.tz(DEFAULT_TIMEZONE).startOf('day');
        this.dialogService.open<any, Moment>({
            title: this.i18n.translate('Date of commissioning of the plant'),
            template: this.setPlantDateDialog,
            context: {
                date: new FormControl(today, [Validators.required]) // use "now" as a start
            }
        }, {
            width: 900
        }).closed.then(effectiveDate => {
            if (effectiveDate) {
                this.utilitiesForm.controls['pod_' + pod + '_pv_plant_effective_date'].setValue(effectiveDate.startOf('day'));
                this.utilitiesForm.controls['pod_' + pod + '_pv_plant_effective_date'].disable();
            }
        });
    }

    getPodUtilityTypes(juridicalType: ECJuridicalType, legalForm: ECLegalForm, subjectType: ECSubjectType,
                       entitySubtype: ECEntitySubtype): ECPodUtilityType[] {
        if (juridicalType === ECJuridicalType.INDIVIDUAL) {
            return PHYSICAL_POD_UTILITY_TYPES[legalForm];
        } else if (juridicalType === ECJuridicalType.CORPORATE_ENTITY) {
            if (subjectType === ECSubjectType.TERRITORIAL_BODY_LOCAL_AUTHORITY_OTHER_BODY) {
                if (entitySubtype) {
                    return (JURIDICAL_POD_UTILITY_TYPES[subjectType])[entitySubtype];
                }
                return [ECPodUtilityType.OTHER];  // return OTHER if we have no entity subtype set
            }
            return JURIDICAL_POD_UTILITY_TYPES[subjectType];
        } else {
            console.error('juridical type not selected');
            return [];
        }
    }

    private setMissingFieldsConfigurability() {
        this.configurabilitySteps = {  // field name (string): is_present (boolean)
            stepPersonalData: {
                subject_type: this.isThisPresent(AllFieldTypes.SUBJECT_TYPE),
                first_name: this.isThisPresent(AllFieldTypes.FIRST_NAME),
                last_name: this.isThisPresent(AllFieldTypes.LAST_NAME),
                company_name: this.isThisPresent(AllFieldTypes.COMPANY_NAME),
                address: this.isThisPresent(AllFieldTypes.ADDRESS),
                custom_address: this.isThisPresent(AllFieldTypes.ADDRESS),
                tax_id: this.isThisPresent(AllFieldTypes.TAX_ID),
                email: this.isThisPresent(AllFieldTypes.EMAIL),
                pec: this.isThisPresent(AllFieldTypes.PEC),
                piva: this.isThisPresent(AllFieldTypes.PIVA),
                mobile_number: this.isThisPresent(AllFieldTypes.MOBILE_NUMBER)
            },
            stepUtilities: {
                pod: this.isThisPresent(AllFieldTypes.POD),
                pod_utility_type: this.isThisPresent(AllFieldTypes.POD_UTILITY_TYPE),
                pod_address: this.isThisPresent(AllFieldTypes.POD_ADDRESS),
                pod_custom_address: this.isThisPresent(AllFieldTypes.POD_ADDRESS),
                wifi: this.isThisPresent(AllFieldTypes.WIFI),
                has_utility_plant: this.isThisPresent(AllFieldTypes.HAS_UTILITY_PLANT),
                utility_production_type: this.isThisPresent(AllFieldTypes.UTILITY_PRODUCTION_TYPE),
                power: this.isThisPresent(AllFieldTypes.POWER),
                pv_plant_effective_date: this.isThisPresent(AllFieldTypes.PV_PLANT_EFFECTIVE_DATE),
                provider: this.isThisPresent(AllFieldTypes.PROVIDER)
            }
        };
        this.updateLimitHeight();
    }

    private getProfilationsControlsValueChanges(): Observable<any>[] {
        return Object.keys(this.profilationsForm.controls)  // all controls relevant to profilations
            .filter(ctrlStr => ctrlStr.endsWith('_people') ||
                ctrlStr.endsWith('_tv') ||
                ctrlStr.endsWith('_pc') ||
                ctrlStr.endsWith('_ac') ||
                ctrlStr.endsWith('_dishwasher') ||
                ctrlStr.endsWith('_heater') ||
                ctrlStr.endsWith('_custom_range'))
            .map(ctrlStr => this.profilationsForm.controls[ctrlStr].valueChanges.pipe(map((val: string) => {
                return {podId: this.extractPodIdFromCtrlStr(ctrlStr), value: val};
            })));
    }

    private extractPodIdFromCtrlStr(str: string): string {
        return str.split('_')[1];  // given the ctrl form of "pod_podId_attribute"
    }

    private manageYearlyConsumptionFetching() {
        this.yearlyConsSubscription = merge(...this.getProfilationsControlsValueChanges())
            .pipe(debounceTime(250),
                takeUntil(this.destroyed))
            .subscribe((temp: { podId: string, value: string }) => {  // value isn't even relevant
                const util = this.mkTempUtil(temp.podId);
                this.updateYearlyConsumption(util, temp.podId);
            });
    }

    private mkTempUtil(podId: string): PreMemberUtility {
        return {
            pod: 'temp_' + podId,
            zone: this.utilitiesForm.controls['pod_' + podId + '_zone'].value,
            profiling: {
                yearly_consumption: null,
                profile_custom_range: this.profilationsForm.controls['pod_' + podId + '_custom_range'].value,
                people: this.profilationsForm.controls['pod_' + podId + '_people'].value,
                tv: this.profilationsForm.controls['pod_' + podId + '_tv'].value,
                pc: this.profilationsForm.controls['pod_' + podId + '_pc'].value,
                ac: this.profilationsForm.controls['pod_' + podId + '_ac'].value,
                dishwasher: this.profilationsForm.controls['pod_' + podId + '_dishwasher'].value,
                heater: this.profilationsForm.controls['pod_' + podId + '_heater'].value
            } as PreMemberProfiling,
            pod_utility_type: this.utilitiesForm.controls['pod_' + podId + '_type'].value,
            address: null,
            custom_address: null,
            has_plant: null,
            collocated: null,
            has_wifi: null
        };
    }

    private managePodAddressLikeMemberChange(podId: string) {
        this.utilitiesForm.controls['pod_' + podId + '_address_like_member'].valueChanges.pipe(takeUntil(this.destroyed)).subscribe(
            value => {
                if (value) {
                    this.addressPodFormControl(podId).disable();
                    this.addressPodFormControl(podId).clearValidators();  // This is not enough
                    this.addressPodFormControl(podId).setValue(this.addressFormControl.value);
                    this.customAddressPodFormControl(podId).disable();
                    this.customAddressPodFormControl(podId).setValue(this.customAddressFormControl.value);
                    if (this.customAddressEnabled.getValue()) {
                        this.podCustomAddressEnabled.next({pod: podId, enabled: true});
                        this.podsCustomAddressEnabled[podId] = true;
                    }
                    // Set coordinates and zone from temp values of copied address
                    this.coordinatesPodFormControl(podId).setValue(this.temp.coordinates);
                    this.zonePodFormControl(podId).setValue(this.getZone(this.temp.region));
                } else {
                    this.addressPodFormControl(podId).enable();
                    this.customAddressPodFormControl(podId).enable();
                    this.addressPodFormControl(podId).setValidators([Validators.required]);
                    this.addressPodFormControl(podId).setValue(null);
                    this.customAddressPodFormControl(podId).setValue(null);
                    this.coordinatesPodFormControl(podId).setValue(null);
                }
            }
        );
    }

    private managePodTypeChange(podId: string) {
        this.utilitiesForm.controls['pod_' + podId + '_type'].valueChanges.pipe(takeUntil(this.destroyed)).subscribe(
            () => {
                this.profilationsForm.controls['pod_' + podId + '_estimated_yearly_consumption'].setValue(null);
            }
        );
    }

    private mkEmailRecap(req: PreMemberRequest): NionMessage {
        return {
            recipient: req.personal_data.email,
            params: {
                firstName: req.personal_data.first_name,
                lastName: req.personal_data.last_name,
                subjectType: req.personal_data.subject_type ?
                    this.i18n.translate(this.subjectTypePipe.transform(req.personal_data.subject_type)) : null,
                legalForm: req.personal_data.legal_form ?
                    this.i18n.translate(this.legalFormPipe.transform(req.personal_data.legal_form)) : null,
                companyName: req.personal_data.company_name || null,
                taxCode: req.personal_data.tax_code || null,
                address: this.addressIsMissing(req.personal_data.address) ? null : req.personal_data.address,
                email: req.personal_data.email,
                mobileNumber: req.personal_data.mobile_number || null,
                docs: req.personal_data.docs.map(d => this.docToNeon(this.getDocName(d.document_type))) || null,
                utilities: req.utilities.map(u => this.utilityToNeon(u)),
                preferredCommunity: this.getCommunityForRecap(req.preferred_community?.name)
            }
        };
    }

    private utilityToNeon(u: PreMemberUtility) {
        return {  // those are the fields the SNS template expects, you have to keep those aligned
            pod: u.pod,
            podUtilityType: this.pipe.transform(u.pod_utility_type),
            address: this.addressIsMissing(u.address) ? null : u.address,
            hasWifi: u.has_wifi,
            profiling: {
                people: u.profiling.people,
                profileCustomRange: u.profiling.profile_custom_range,
                totalConsumption: u.profiling.yearly_consumption
            }
        };
    }

    // This happens when configurability removes the address field
    private addressIsMissing(address: ECMemberAddress): boolean {
        if (!address) {
            return true;
        }
        return (!address.street && !address.city && !address.street_number && !address.province && !address.postal_code);
    }

    private getCommunityForRecap(name: string) {
        // If no instance communities are found, then return null
        // otherwise, return the preference name or "no preference"
        if (this.instanceCommunities) {
            return name || this.i18n.translate('No preference');
        } else {
            return null;
        }
    }

    private getPreferredCommunity(pref: PreMemberCommunity) {
        // If no instance communities are found or the selected value is "0" (no_preference), then return null
        // otherwise, return the preference
        if (this.instanceCommunities) {
            return (pref.id !== '0') ? pref : null;
        } else {
            return null;
        }
    }

    private docToNeon(docName: string) {
        // SNS expects an object having the "name" attribute
        return {
            name: this.pipe.transform(docName)
        };
    }

    private getFormData(): PreMemberRequest {
        return {
            personal_data: {
                juridical_type: this.personalDataForm.controls.juridicalType.value,
                legal_form: this.personalDataForm.controls.legalForm.value,
                subject_type: this.personalDataForm.controls.subjectType.value,
                entity_subtype: this.personalDataForm.controls.entitySubtype.value,
                email: this.personalDataForm.controls.email.value,
                pec: this.personalDataForm.controls.pec ? this.personalDataForm.controls.pec.value : null,
                company_name: this.personalDataForm.controls.companyName ? this.personalDataForm.controls.companyName.value : null,
                piva: this.personalDataForm.controls.vatNumber ? this.personalDataForm.controls.vatNumber.value : null,
                tax_code: this.personalDataForm.controls.taxCode ? this.personalDataForm.controls.taxCode.value : null,
                first_name: this.personalDataForm.controls.firstName.value,
                last_name: this.personalDataForm.controls.lastName.value,
                address: this.parseAddress(this.personalDataForm.controls.address.value),
                custom_address: this.personalDataForm.controls.custom_address.value,
                mobile_number: this.personalDataForm.controls.mobile.value,
                docs: this.getDocuments(),
                spatial_coordinates: this.memberCoordinates
            },
            utilities: this.getUtilities(),
            policy: {
                rules: true,
                privacy: true
            },
            preferred_community: this.getPreferredCommunity(this.personalDataForm.controls.preferredCommunity.value)
        };
    }

    private getDocuments(): PreMemberDocument[] {
        const docs: PreMemberDocument[] = [];
        this.docTypes.forEach(doc => {
            // IF file is not present for current doc, do nothing
            if (!this.tmpDocuments[doc.name]?.fileName || !this.tmpDocuments[doc.name]?.file) {
                return;
            }
            const d: PreMemberDocument = {
                document_type: doc.name,
                data: null,
                filename: this.tmpDocuments[doc.name]?.fileName
            };
            docs.push(d);
        });
        return docs;
    }

    private getUtilities(): PreMemberUtility[] {
        const pods: PreMemberUtility[] = [];
        this.pods.forEach(pod => {
            const p: PreMemberUtility = {
                pod: this.utilitiesForm.controls[`pod_${pod}_code`].value,
                pod_utility_type: this.utilitiesForm.controls[`pod_${pod}_type`].value,
                address: this.parseAddress(this.utilitiesForm.controls[`pod_${pod}_address`].value),
                custom_address: this.utilitiesForm.controls[`pod_${pod}_custom_address`].value,
                has_wifi: this.utilitiesForm.controls[`pod_${pod}_wifi`].value,
                has_plant: this.utilitiesForm.controls[`pod_${pod}_utility_type`].value,
                production_type: this.utilitiesForm.controls[`pod_${pod}_plant_type`].value,
                profiling: this.getProfiling(pod),
                power: this.sanityReplaceFloat(this.utilitiesForm.controls[`pod_${pod}_power`].value),
                energy_provider: this.utilitiesForm.controls[`pod_${pod}_provider`].value,
                collocated: false,
                spatial_coordinates: this.utilitiesForm.controls[`pod_${pod}_coordinates`].value,
                zone: this.utilitiesForm.controls[`pod_${pod}_zone`].value,
                pv_plant_effective_date: this.utilitiesForm.controls[`pod_${pod}_pv_plant_effective_date`].value ?
                    this.utilitiesForm.controls[`pod_${pod}_pv_plant_effective_date`].value.valueOf() : null
            };
            pods.push(p);
        });
        return pods;
    }

    private parseAddress(addressString: string): ECMemberAddress | null {
        if (addressString === null) {
            return null;
        }
        const parts = addressString.split(', ');

        if (parts.length >= 4) {
            const [street, streetNumber, postalAndCity, nation] = parts;
            const [postalCode, city, province] = postalAndCity.split(' ');

            return {
                street: street.trim(),
                street_number: streetNumber.trim(),
                city: city.trim(),
                province: province.trim(),
                postal_code: postalCode.trim(),
            };
        }

        return null;
    }

    private getZone(region: string): string {
        if (!region) {
            return null;
        }
        for (const item of googleZoneMap) {
            if (item.regions.includes(region)) {
                return item.zone;
            }
        }
        return null;
    }

    private getProfiling(pod: string): PreMemberProfiling {
        // If profilation steps isn't included, send null values for everything
        if (!this.STEPS.includes(ENERGY_PROFILING)) {
            return {
                yearly_consumption: null,
                profile_custom_range: null,
                people: null,
                tv: null,
                pc: null,
                ac: null,
                dishwasher: null,
                heater: null
            };
        }
        if (!this.secondPartPodValue(pod)) {  // we send estimated yearly cons and profile custom range
            return {
                yearly_consumption: this.profilationsForm.controls[`pod_${pod}_estimated_yearly_consumption`].value,
                profile_custom_range: this.profilationsForm.controls[`pod_${pod}_custom_range`].value,
                people: null,
                tv: null,
                pc: null,
                ac: null,
                dishwasher: null,
                heater: null
            };
        } else {  // we inputted yearly consumption
            return {
                yearly_consumption: this.sanityReplaceFloat(this.profilationsForm.controls[`pod_${pod}_yearly_consumption`].value),
                profile_custom_range: null,
                people: null,
                tv: null,
                pc: null,
                ac: null,
                dishwasher: null,
                heater: null
            };
        }
    }

    private sanityReplaceFloat(val): number {
        return val ? +val.replace(',', '.') : null;
    }

    private generatePODId(): string {
        const podIds = getPODIds(this.utilitiesForm.controls);
        if (!podIds.length) {
            return '1';
        }
        const newId = podIds.map(podId => parseInt(podId, 10))
            .reduce((pv, cv) => Math.max(pv, cv), 0) + 1;
        return newId.toString(10);
    }

    private addPodControls(podId: string) {
        this.podCustomAddressEnabled.next({pod: podId, enabled: false})
        this.utilitiesForm.addControl(`pod_${podId}_code`, new FormControl(null,
            this.configurabilitySteps.stepUtilities.pod ? [validateNotBlank, Validators.pattern(POD_PATTERN)] : [], [ctrl => this.validatePOD(ctrl)]));
        this.utilitiesForm.addControl(`pod_${podId}_type`, new FormControl(null,
            this.configurabilitySteps.stepUtilities.pod_utility_type ? [Validators.required] : []));
        this.utilitiesForm.addControl(`pod_${podId}_address`, new FormControl(null,
            this.configurabilitySteps.stepUtilities.pod_address ? [Validators.required] : []));
        this.utilitiesForm.addControl(`pod_${podId}_custom_address`, new FormControl(null,
            this.configurabilitySteps.stepUtilities.pod_custom_address && this.podsCustomAddressEnabled[podId] ? [Validators.required, validateNotBlank] : []));
        this.utilitiesForm.addControl(`pod_${podId}_wifi`, new FormControl(this.configurabilitySteps.stepUtilities.wifi ? false : null,
            this.configurabilitySteps.stepUtilities.wifi ? [Validators.required] : []));
        this.utilitiesForm.addControl(`pod_${podId}_provider`, new FormControl(null, [Validators.pattern(PROVIDER_PATTERN)]));
        this.utilitiesForm.addControl(`pod_${podId}_utility_type`, new FormControl(null,
            this.configurabilitySteps.stepUtilities.has_utility_plant ? [Validators.required] : []));
        this.utilitiesForm.addControl(`pod_${podId}_plant_type`, new FormControl(null,
            this.configurabilitySteps.stepUtilities.utility_production_type ? [Validators.required] : []));
        this.utilitiesForm.addControl(`pod_${podId}_power`, new FormControl(null,
            this.configurabilitySteps.stepUtilities.power ? [Validators.required, ctrl => validateFloat(ctrl, this.i18n.language, 0)] : []));
        this.utilitiesForm.addControl(`pod_${podId}_pv_plant_effective_date`, new FormControl(null, []));
        this.utilitiesForm.addControl(`pod_${podId}_coordinates`, new FormControl(null, []));
        this.utilitiesForm.addControl(`pod_${podId}_zone`, new FormControl(null, []));
        this.utilitiesForm.addControl(`pod_${podId}_address_like_member`, new FormControl(false, []));
        if (this.STEPS.includes(ENERGY_PROFILING)) {
            this.profilationsForm.addControl(`pod_${podId}_people`, new FormControl(null, [validateInteger]));
            this.profilationsForm.addControl(`pod_${podId}_tv`, new FormControl(null, [validateInteger]));
            this.profilationsForm.addControl(`pod_${podId}_pc`, new FormControl(null, [validateInteger]));
            this.profilationsForm.addControl(`pod_${podId}_ac`, new FormControl(null, [validateInteger]));
            this.profilationsForm.addControl(`pod_${podId}_dishwasher`, new FormControl(false, []));
            this.profilationsForm.addControl(`pod_${podId}_heater`, new FormControl(false, []));
            this.profilationsForm.addControl(`pod_${podId}_custom_range`, new FormControl(null, []));
            this.profilationsForm.addControl(`pod_${podId}_yearly_consumption`, new FormControl(null, [ctrl => validateFloat(ctrl, this.i18n.language, 0)]));
            this.profilationsForm.addControl(`pod_${podId}_estimated_yearly_consumption`, new FormControl(null, []));
            this.profilationsForm.addControl(`pod_${podId}_second_part`, new FormControl(!this.ecmLinked, []));  // ECM not linked means we default to second radio btn
        }
        if (this.configurabilitySteps.stepUtilities.pod_custom_address) {
            this.podCustomAddressEnabled.asObservable().pipe(takeUntil(this.destroyed)).subscribe(podCustomAddress => {
                if (podCustomAddress.pod === podId) {
                    this.utilitiesForm.controls[`pod_${podId}_custom_address`].setValidators([validateNotBlank, Validators.required]);
                    if (podCustomAddress.enabled === false) {
                        this.utilitiesForm.controls[`pod_${podId}_custom_address`].clearValidators();
                    }
                }
                this.utilitiesForm.controls[`pod_${podId}_custom_address`].updateValueAndValidity();
            })
        }

    }

    private validateFile(file: File): boolean {
        if (!FILE_MIME_TYPES.find(mt => mt.type === file.type)) {
            this.msgBoxService.error(this.i18n.translate('File {0} is not a {1} file', file.name, this.mimeTypesNames));
            return false;
        }
        if (file.size > MAX_FILE_MB * 1048576) {
            this.msgBoxService.error(this.i18n.translate('Size of file {0} must be below {1} MB', file.name, MAX_FILE_MB));
            return false;
        }
        return true;
    }

    private updateForms() {
        // tslint:disable-next-line:forin
        for (const form of [this.profilationsForm, this.utilitiesForm, this.personalDataForm, this.acceptanceForm]) {
            // tslint:disable-next-line:forin
            for (const ctrl in form.controls) {
                form.controls[ctrl].updateValueAndValidity({onlySelf: true, emitEvent: true});
            }
            form.updateValueAndValidity({onlySelf: true, emitEvent: true});
        }
    }

    private onChangeResetPodsVals() {
        if (this.pods.length > 0) {
            this.pods.forEach(podId => {
                if (this.STEPS.includes(ENERGY_PROFILING)) {
                    this.profilationsForm.controls['pod_' + podId + '_custom_range'].reset();
                }
                this.utilitiesForm.controls['pod_' + podId + '_type'].reset();  // this also resets estimated yearly cons
            });
        }
    }

    private onChangeSubjectType(selectedJuridicalType: ECJuridicalType) {
        this.personalDataForm.controls.legalForm.reset();
        this.personalDataForm.controls.entitySubtype.reset();
        switch (selectedJuridicalType) {
            case ECJuridicalType.CORPORATE_ENTITY:
                this.personalDataForm.controls.taxCode.reset();
                this.personalDataForm.controls.legalForm.clearValidators();
                this.personalDataForm.controls.legalForm.updateValueAndValidity({emitEvent: false});
                this.personalDataForm.controls.subjectType.setValidators([Validators.required]);
                this.personalDataForm.controls.subjectType.updateValueAndValidity({emitEvent: false});
                break;
            case ECJuridicalType.INDIVIDUAL:
                this.personalDataForm.controls.companyName.reset();
                this.personalDataForm.controls.vatNumber.reset();
                this.personalDataForm.controls.legalForm.setValidators([Validators.required]);
                this.personalDataForm.controls.legalForm.updateValueAndValidity({emitEvent: false});
                this.personalDataForm.controls.subjectType.clearValidators();
                this.personalDataForm.controls.subjectType.updateValueAndValidity({emitEvent: false});
                break;
            default:
                break;
        }
        // manage height limit in personal data form
        this.updateLimitHeight();
    }

    private updateLimitHeight() {
        this.turboMaxHeight = Object.entries(this.configurabilitySteps.stepPersonalData).filter(([key, value]) => {
            if (!this.isCompany) {
                return value && key !== AllFieldTypes.COMPANY_NAME && key !== AllFieldTypes.PIVA;
            } else {  // private and public institution
                return value && key !== AllFieldTypes.TAX_ID;
            }
        }).length;
        // Add one to the counter if email is present (since we also have "confirm email step")
        if (this.configurabilitySteps.stepPersonalData.email) {
            this.turboMaxHeight += 1;
        }
        // Add one to the counter if address is present
        if (this.configurabilitySteps.stepPersonalData.address) {
            this.turboMaxHeight += 1;
        }
        if (this.customAddressEnabled.getValue()) {
            this.turboMaxHeight += 3;
        }
    }

    private validateForm(ctrl: AbstractControl): ValidationErrors | null {
        const form = ctrl as FormGroup;
        // Check both emails are the same
        if (form.controls.email.dirty &&
            form.controls.confirmEmail.dirty &&
            (form.controls.email.value !== form.controls.confirmEmail.value)) {
            return {different_emails: true};
        }
        return null;
    }

    private validateDocuments(): boolean {
        // check if every mandatory document has been uploaded
        return this.docTypes
            .filter(dt => !dt.optional)
            .map(dt => dt.name)
            .reduce((res, key) => res && !!this.tmpDocuments[key], true);
    }

    private openSpinner(message: string) {
        const spinnerData = new BehaviorSubject<SpinnerData>({
            loading: true,
            loadingMessage: this.i18n.translate(message)
        });
        return this.dialogService.open<Subject<SpinnerData>, undefined>({
            template: this.spinnerDialog,
            context: spinnerData
        }, {
            width: DIALOG_WIDTH,
            hideCloseButton: true
        });
    }

    private validatePOD(ctrl: AbstractControl): Observable<{ [err: string]: boolean }> {
        const pod = mkTextField(ctrl.value);
        if (!pod) {
            return of(null);
        }
        return of(this.areDuplicatePodsInserted(pod));
    }

    private areDuplicatePodsInserted(pod: string): { [err: string]: boolean } {
        this.podsIds = this.pods.map(id => this.utilitiesForm.controls[`pod_${id}_code`].value);
        // If next condition is met, it means we have duplicate values inside our 'this.pods'
        const singletonPods = new Set(this.podsIds);
        if (singletonPods.size !== this.podsIds.length && this.podsIds.filter(id => id === pod).length > 1) {
            return {duplicate_pods: true};
        }
        if (singletonPods.size === this.podsIds.length) {
            this.pods.map(id => {
                this.utilitiesForm.controls[`pod_${id}_code`].setErrors(clearErrors(this.utilitiesForm.controls[`pod_${id}_code`].errors, 'duplicate_pods'));
            });
        }
        return null;
    }

    onClickCustomAddress($event: MouseEvent) {
        this.customAddressEnabled.next(!this.customAddressEnabled.getValue())
    }

    onClickPodCustomAddress(pod: string) {
        this.podCustomAddressEnabled.next({pod: pod, enabled: !this.podsCustomAddressEnabled[pod]})
        this.podsCustomAddressEnabled[pod] = !this.podsCustomAddressEnabled[pod];
    }
}


function getPODIds(ctrls: { [ctrl: string]: AbstractControl }): string[] {
    return Object.keys(ctrls)
        .filter(name => name.startsWith('pod_')
            && name.endsWith('_code')
            && !name.endsWith('postal_code'))
        .map(name => name.substring(4, name.length - 5));
}

function mkTextField(value: string): string {
    const tv = value ? value.trim() : '';
    return tv.length ? tv : undefined;
}
