import {Pipe, PipeTransform} from '@angular/core';
import {I18NService} from '../i18n/i18n.service';
import {HasUtilityPlant} from 'pms-commons/premember';
import {
    ECEntitySubtype,
    ECJuridicalType,
    ECLegalForm,
    ECPodUtilityType,
    ECSubjectType
} from 'smag-commons/cfg/hierarchy';

@Pipe({name: 'genericPipe'})
export class GenericPipe implements PipeTransform {

    constructor(private readonly i18n: I18NService) {
    }

    transform(value: string | ECPodUtilityType): string {
        return this.i18n.translate((value.charAt(0).toUpperCase() + value.slice(1)).replace(/_/g, ' '));
    }
}


@Pipe({name: 'hasUtilityPlant'})
export class HasUtilityPlantPipe implements PipeTransform {

    constructor(private readonly i18n: I18NService) {
    }

    transform(value: HasUtilityPlant): string {
        switch (value) {
            case HasUtilityPlant.NO:
                return this.i18n.translate('As a consumer');
            case HasUtilityPlant.YES:
                return this.i18n.translate('As a prosumer (I already have a plant)');
            case HasUtilityPlant.FUTURE:
                return this.i18n.translate('As an aspiring prosumer (I still don\'t have a plant)');
            default:
                return value;
        }
    }
}

@Pipe({name: 'ecMemberJuridicalType'})
export class ECMemberJuridicalTypePipe implements PipeTransform {

    constructor() {
    }

    transform(type: ECJuridicalType): string {
        switch (type) {
            case ECJuridicalType.CORPORATE_ENTITY:
                return 'Corporate entity';
            case ECJuridicalType.INDIVIDUAL:
                return 'Individual';
            default:
                return type;
        }
    }
}

@Pipe({name: 'ecMemberLegalFormType'})
export class ECMemberLegalFormTypePipe implements PipeTransform {

    constructor() {
    }

    transform(type: ECLegalForm): string {
        switch (type) {
            case ECLegalForm.OTHER_INDIVIDUALS:
                return 'Other individuals';
            case ECLegalForm.ENTREPRENEUR_SOLE_PROPRIETORSHIP_AGRICULTURAL_ENTERPRISE:
                return 'Entrepreneur, sole proprietorship, agricultural enterprise';
            default:
                return type;
        }
    }
}

@Pipe({name: 'ecMemberSubjectType'})
export class ECMemberSubjectTypePipe implements PipeTransform {

    constructor() {
    }

    transform(type: ECSubjectType): string {
        switch (type) {
            case ECSubjectType.PMI:
                return 'SME';
            case ECSubjectType.TERRITORIAL_BODY_LOCAL_AUTHORITY_OTHER_BODY:
                return 'Territorial body, local authority, other body';
            case ECSubjectType.OTHER:
                return 'Other';
            case ECSubjectType.ASSOCIATION_WITH_LEGAL_PERSONALITY_UNDER_PRIVATE_LAW:
                return 'Association with legal personality under private law';
            case ECSubjectType.ASSOCIATED_FIRM_COMPANY_OF_PROFESSIONALS:
                return 'Associated firm company of professionals';
            case ECSubjectType.COMMUNITY:
                return 'Community';
            default:
                return type;
        }
    }
}

@Pipe({name: 'ecMemberEntitySubtype'})
export class ECMemberEntitySubtypePipe implements PipeTransform {

    constructor() {
    }

    transform(type: ECEntitySubtype): string {
        switch (type) {
            case ECEntitySubtype.ENVIRONMENT_PREVENTION_ORGANIZATION:
                return 'Environment prevention organization';
            case ECEntitySubtype.STATE:
                return 'State';
            case ECEntitySubtype.REGION:
                return 'Region';
            case ECEntitySubtype.ISLAND_COMMUNITY:
                return 'Island community';
            case ECEntitySubtype.PROVINCE:
                return 'Province';
            case ECEntitySubtype.LOCAL_ADMINISTRATION_ORGANIZATION:
                return 'Local administration organization';
            case ECEntitySubtype.MOUNTAIN_COMMUNITY:
                return 'Mountain community';
            case ECEntitySubtype.METROPOLITAN_CITY:
                return 'Metropolitan city';
            case ECEntitySubtype.RELIGIOUS_ORGANIZATION:
                return 'Religious organization';
            case ECEntitySubtype.RESEARCH_AND_TRAINING_ORGANIZATION:
                return 'Research and training organization';
            case ECEntitySubtype.MUNICIPALITY:
                return 'Municipality';
            case ECEntitySubtype.MUNICIPALITIES_UNION:
                return 'Municipalities union';
            case ECEntitySubtype.THIRD_SECTOR_ORGANIZATION:
                return 'Third sector organization';
            default:
                return type;
        }
    }
}

