import {InjectionToken} from '@angular/core';
import {CommonsConfig, Pills} from './commons/commons.config';
import {ApiConfig} from './api/api-config';
import {I18NConfig} from './i18n/i18n.service';
import {environment} from 'src/environments/environment';

export class AppConfig {
    commons: CommonsConfig;
    api: ApiConfig;
    i18n: I18NConfig;
    toasterTimeout: number;
    rootPath: string;
    domainPrefix?: string;
    pills: Pills[];
    showHomePage: boolean;
    showPills: boolean;
    showIncentives: boolean;
    showForm: boolean;
    showBreadCrumb: boolean;
    prospectusTitle: string;
    prospectusBody: string[];
    cookiePolicyLink: string;
}

export const APP_CONFIG_TOKEN = new InjectionToken<AppConfig>('app.config');

export const APP_CONFIG: AppConfig = {
    commons: {name: 'Application panel', version: '0.0.0'},
    api: {},
    i18n: {},
    toasterTimeout: 2000,
    rootPath: environment.rootPath,
    domainPrefix: environment.domainPrefix,
    pills: environment.pills,
    showHomePage: environment.showSections.showHomePage,
    showPills: environment.showSections.showPills,
    showIncentives: environment.showSections.showIncentives,
    showForm: environment.showSections.showForm,
    showBreadCrumb: environment.showSections.showBreadCrumb,
    prospectusTitle: environment.prospectusTitle,
    prospectusBody: environment.prospectusBody,
    cookiePolicyLink: environment.cookiePolicyLink,
};
