import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ContentComponent} from './layout/content/content.component';
import {LayoutModule} from './layout/layout.module';
import {I18nModule} from './i18n/i18n.module';
import {ApplicationComponent} from './views/application/application.component';
import {ApplicationFormComponent} from './views/application-form/application-form.component';
import {ApplicationPillsComponent} from './views/application-pills/application-pills.component';
import {PillContentComponent} from './views/application-pills/pill-content/pill-content.component';
import {ApplicationIncentivesComponent} from './views/application-incentives/application-incentives.component';
import {EnvironmentGuard} from './environment.guard';

const routes: Routes = [
    {
        path: 'c',
        component: ContentComponent,
        children: [
            {
                path: 'application',
                component: ApplicationComponent,
                data: {title: 'Premembership Home'},
                canActivate: [EnvironmentGuard]
            },
            {
                path: 'application-form',
                component: ApplicationFormComponent,
                data: {title: 'How to participate'},
                canActivate: [EnvironmentGuard]
            },
            {
                path: 'application-pills',
                component: ApplicationPillsComponent,
                data: {title: 'Informative pills'},
                canActivate: [EnvironmentGuard]
            },
            {
                path: 'pill-content/:id',
                component: PillContentComponent,
                data: {title: 'Pill content'},
                canActivate: [EnvironmentGuard]
            },
            {
                path: 'application-incentives',
                component: ApplicationIncentivesComponent,
                data: {title: 'State incentives'},
                canActivate: [EnvironmentGuard]
            },
            {
                path: '',
                redirectTo: 'application',
                pathMatch: 'full'
            }
        ],
        resolve: {}
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'c'
    },
    {
        path: '**',
        redirectTo: 'c'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        // app
        LayoutModule,
        I18nModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
