// angular
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
// api
import {CommonsModule} from '../commons/commons.module';
import {ApiConfig} from './api-config';
import {ApiService} from './api.service';
import {HttpErrorInterceptor} from './http-error-interceptor.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        CommonsModule
    ],
    declarations: []
})
export class ApiModule {

    /**
     * Configures the ApiModule.
     *
     * @param config Module configuration
     * @return The module with the providers
     */
    static forRoot(config: ApiConfig): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [
                {provide: ApiConfig, useValue: config},
                ApiService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpErrorInterceptor,
                    multi: true
                }
            ]
        };
    }
}
