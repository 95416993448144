// angular
import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';

// i18n
import {I18NConfig, I18NService, load} from './i18n.service';
import {I18NDatePipe, I18NDateTimePipe, I18NParsePipe, I18NPipe, I18NTimestampPipe} from './i18n.pipe';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [
        I18NPipe,
        I18NParsePipe,
        I18NDatePipe,
        I18NDateTimePipe,
        I18NTimestampPipe
    ],
    exports: [
        I18NPipe,
        I18NParsePipe,
        I18NDatePipe,
        I18NDateTimePipe,
        I18NTimestampPipe,
    ]
})
export class I18nModule {
    static forRoot(config: I18NConfig): ModuleWithProviders<I18nModule> {
        return {
            ngModule: I18nModule,
            providers: [
                {provide: I18NConfig, useValue: config},
                I18NService,
                /* next line loads the i18n configuration from server during bootstrap */
                {provide: APP_INITIALIZER, useFactory: load, deps: [HttpClient, I18NConfig, I18NService], multi: true}
            ]
        };
    }
}
