export const POD_MAX_CHARS = 15;
export const NAME_MAX_CHARS = 32;
export const EMAIL_MAX_CHARS = 64;
export const MOBILE_MAX_CHARS = 15;
export const TAX_ID_MAX_CHARS = 16;
export const POSTAL_CODE_MAX_CHARS = 5;
export const PROFILATIONS_MAX_CHARS = 4;
export const TOTAL_CONSUMPTION_MAX_CHARS = 8;
export const VAT_NUMBER_MAX_CHARS = 12;
export const DEFAULT_MAX_CHARS = 50;
