import {Injectable} from '@angular/core';

/**
 * Service which takes care of storing data in local/session storage.
 */
@Injectable()
export class StorageService {

    constructor() {
    }

    storeSession(key: string, data: any): void {
        try {
            sessionStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.error(`Error saving key ${key} to session storage`, e);
        }
    }

    loadSession(key: string): any {
        try {
            const value = sessionStorage.getItem(key);
            return value === null ? null : JSON.parse(value);
        } catch (e) {
            console.error(`Error getting key ${key} from session storage`, e);
            return null;
        }
    }

    removeSession(key: string): void {
        try {
            sessionStorage.removeItem(key);
        } catch (e) {
            console.error(`Error removing key ${key} from session storage`, e);
        }
    }

    storeLocal(key: string, data: any): void {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.error(`Error saving key ${key} to local storage`, e);
        }
    }

    loadLocal(key: string): any {
        try {
            const value = localStorage.getItem(key);
            return value === null ? null : JSON.parse(value);
        } catch (e) {
            console.error(`Error getting key ${key} from local storage`, e);
            return null;
        }
    }

    removeLocal(key: string): void {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            console.error(`Error removing key ${key} from local storage`, e);
        }
    }
}
