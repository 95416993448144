import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ToasterModule} from 'angular2-toaster';

import {CommonsModule} from '../commons/commons.module';
import {SharedModule} from '../shared/shared.module';
import {ContentComponent} from './content/content.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {I18nModule} from '../i18n/i18n.module';
import {ViewsModule} from '../views/views.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        ContentComponent,
        HeaderComponent,
        FooterComponent
    ],
    imports: [
        // angular
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        // 3rd party
        ToasterModule.forChild(),
        // app
        SharedModule,
        CommonsModule,
        I18nModule,
        ViewsModule
    ],
    exports: [
        ContentComponent
    ]
})
export class LayoutModule {

    /**
     * Configures the LayoutModule.
     *
     * @return The module with the providers
     */
    static forRoot(): ModuleWithProviders<LayoutModule> {
        return {
            ngModule: LayoutModule
        };
    }
}
