// angular
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

// 3rd party
import {ToasterModule} from 'angular2-toaster';
import {AgmCoreModule} from '@agm/core';
// application
import {ApiModule} from './api/api.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {APP_CONFIG, APP_CONFIG_TOKEN} from './app.config';
import {CommonsModule} from './commons/commons.module';
import {I18nModule} from './i18n/i18n.module';
import {SharedModule} from './shared/shared.module';
import {LayoutModule} from './layout/layout.module';
import {ViewsModule} from './views/views.module';
import {EnvironmentGuard} from './environment.guard';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        // angular
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        // 3rd party
        ToasterModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDwa3xMngKKvsBhhptjpEueE8OrS_Tx-cE',
            libraries: ['places']
        }),
        // app modules
        CommonsModule.forRoot(APP_CONFIG.commons),
        ApiModule.forRoot(APP_CONFIG.api),
        I18nModule.forRoot(APP_CONFIG.i18n),
        SharedModule.forRoot({}),
        LayoutModule.forRoot(),
        ViewsModule.forRoot(),
        // app routing
        AppRoutingModule
    ],
    providers: [
        [EnvironmentGuard],
        {provide: APP_CONFIG_TOKEN, useValue: APP_CONFIG}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
