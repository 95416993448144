export interface DocConfig {
    name: string;
    optional: boolean;
}

// This enum describes the type of data we have; New types have to be defined here
// Those decide which html section is going to be rendered and which FormControls are added;
export enum FieldType {
    INPUT,
    DATE
}

export interface NionMessage {
    name?: string;
    recipient?: string;
    params?: object;
    locale?: string;
    target?: 'WEB' | 'EMAIL' | 'SMS' | 'PUSH';
    tenant?: string;
    emitter?: string;
}
