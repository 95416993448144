export const DEFAULT_LANGUAGE = 'en';

export const DEFAULT_FORMATS = {
    TIMESTAMP: 'DD/MM/YYYY, HH:mm:ss', // 'll LTS',
    DATETIME: 'DD/MM/YYYY, LT',
    DATE: 'DD/MM/YYYY'
};

export const DATE_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD MM YYY',
        monthYearA11yLabel: 'MM YYYY',
    }
};

export const DEFAULT_TIMEZONE = 'Europe/Rome';
