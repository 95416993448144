import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
// app modules
import {ApiModule} from '../api/api.module';
import {CommonsModule} from '../commons/commons.module';
import {I18nModule} from '../i18n/i18n.module';
import {SharedModule} from '../shared/shared.module';
// app view modules
import {ApplicationComponent} from './application/application.component';
import {ApplicationFormComponent} from './application-form/application-form.component';
import {SpinnerComponent} from '../shared/spinner/spinner.component';
import { ApplicationPillsComponent } from './application-pills/application-pills.component';
import { PillContentComponent } from './application-pills/pill-content/pill-content.component';
import { ApplicationIncentivesComponent } from './application-incentives/application-incentives.component';


@NgModule({
    declarations: [
        ApplicationComponent,
        ApplicationFormComponent,
        SpinnerComponent,
        ApplicationPillsComponent,
        PillContentComponent,
        ApplicationIncentivesComponent
    ],
    imports: [
        // angular
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // application
        ApiModule,
        CommonsModule,
        I18nModule,
        SharedModule
    ],
    exports: [
        ApplicationComponent,
        ApplicationFormComponent
    ]
})
export class ViewsModule {

    /**
     * Configures the ViewsModule.
     *
     * @return The module with the providers
     */
    static forRoot(): ModuleWithProviders<ViewsModule> {
        return {
            ngModule: ViewsModule,
            providers: [

            ]
        };
    }
}
