import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IconPrefix} from '@fortawesome/pro-light-svg-icons';
import {IconName} from '@fortawesome/fontawesome-svg-core';

export enum IconType {
    Mat,
    FA
}

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnChanges {
    @Input() name: string;
    @Input() matClass: string | string[];
    @Input() faClass: string | string[];

    icon: string[];
    type: IconType;
    IconType = IconType;

    constructor() {
        this.icon = [];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.matClass) {
            this.matClass = [];
        }
        if (!this.faClass) {
            this.faClass = [];
        }

        if (!this.name) {
            return;
        }
        if (this.name.startsWith('mat-')) {
            // material icon
            this.icon = [this.name.substr(4)];
            this.type = IconType.Mat;
        } else if (this.name.startsWith('fas-') || this.name.startsWith('fal-')) {
            // fontawesome
            this.icon = [this.name.substr(0, 3) as IconPrefix, this.name.substr(4) as IconName];
            this.type = IconType.FA;
        } else {
            // fallback to material icons
            this.icon = [this.name];
            this.type = IconType.Mat;
        }
    }
}
