// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {AppEnvironment} from './env';

export const environment: AppEnvironment = {
    production: false,
    rootPath: '/api',
    domainPrefix: '',
    copyright: '©$YEAR$ Maps spa',
    cookiePolicyLink: 'https://rose-documents.s3.eu-west-1.amazonaws.com/assets/cookie_policy.pdf',
    address: 'P.Iva/VAT Number 01977490356 l R.E.A. PR-240225 l Capitale sociale: 1.289.985,10 Euro I.V.',
    showSections: {
        showHomePage: true,
        showPills: true,
        showIncentives: true,
        showForm: true,
        showBreadCrumb: true,
    },
    pills: [{
        id: 1,
        title: 'What does it mean to live in a REC?',
        subtitle: 'Here\'s the video with the members\' reports.',
        background: '../../../assets/pills/pill_01.png',
        backgroundHeading: '../../../assets/pills/pill_heading_01.png',
        description: ['Lorem ipsum dolor sit amet consectetur. Vel ac justo consectetur in justo proin. Massa lacus in nisi id laoreet convallis est. Cursus aliquet aliquam neque sit nulla. Diam lectus rhoncus diam ac mus bibendum facilisis. Semper volutpat senectus consequat maecenas nec. Facilisi nunc velit purus faucibus. A commodo consequat a pharetra. Rhoncus vitae mollis vestibulum amet. Pellentesque morbi eu quis egestas est cras id. Sit tincidunt tortor neque vitae a ac eu faucibus. Tellus mattis mattis egestas imperdiet tellus.\n' +
            'Quis amet est congue purus id pretium porta. Sit integer lectus vestibulum at arcu mattis. Aliquet enim tellus consequat eu nibh magna. Tincidunt sed senectus neque purus. Augue lobortis risus massa lectus. Enim duis nisi aliquet sapien neque amet amet vel purus. Enim at lacus ullamcorper amet.\n' +
            'Feugiat mi tincidunt dolor vitae urna porttitor sit arcu. Tincidunt in lacinia diam faucibus at eget egestas. Senectus orci amet ut ac morbi pellentesque. Dui auctor pulvinar ut lobortis nam venenatis habitasse nibh. Faucibus elit proin faucibus sit. Non faucibus sit lectus urna nulla enim luctus.\n',
            'Urna molestie lectus sed neque molestie. Hendrerit amet commodo lectus amet etiam integer rutrum praesent. Fermentum faucibus aliquet rhoncus natoque tempus. Commodo pretium amet viverra elit cursus. Cum ipsum fermentum semper rhoncus vestibulum a suspendisse fermentum. Massa dolor nibh in ac aliquam eget. Lacus auctor velit tempus ultricies leo accumsan dolor orci. Libero quisque ut elementum montes netus arcu tristique.\n' +
            'Nec adipiscing dictum purus faucibus accumsan tempus turpis. Gravida morbi libero enim tristique tellus tellus consequat dignissim varius. Aliquam bibendum fermentum massa faucibus phasellus. Nunc pellentesque proin ultrices lacus venenatis turpis semper risus. Vel quis morbi amet placerat in magna scelerisque. Eu suscipit iaculis semper tempor id diam neque dictumst. Malesuada lorem gravida nullam habitant nulla quisque dolor sodales tincidunt. Elit facilisi platea amet vitae. Metus tristique hendrerit scelerisque purus vitae eu diam vestibulum. Elementum suspendisse non sit sit ut. Sagittis elit sit quis risus. Fermentum at nulla facilisis adipiscing donec vitae. Ultricies diam ipsum sem justo tellus vestibulum.\n',
            'Elementum sed integer eget pulvinar arcu enim justo vitae. Luctus aenean risus id ac tristique lectus quis. A aliquet ut et id nam. Tellus arcu ut eget purus cursus egestas pulvinar facilisis ullamcorper. Enim morbi amet varius purus molestie sit.\n' +
            'Bibendum dapibus ornare dui etiam porta cursus viverra tellus vestibulum. Non sed purus bibendum nulla amet eleifend at mauris. Tincidunt nunc commodo urna nibh leo consequat orci. Sem lacus nisl enim felis lorem platea massa. Eu eget sit imperdiet maecenas.\n' +
            'Fermentum feugiat faucibus tortor at mauris diam libero nulla tellus. Risus aliquet tortor orci quam tempus. Sollicitudin nunc pretium lorem turpis pulvinar cras rhoncus. Aliquam dolor fermentum scelerisque quam commodo non malesuada. Justo sed pellentesque auctor netus vulputate elementum quisque pellentesque. Porta ultrices nisi purus turpis duis.\n' +
            'Mi rhoncus varius id sapien semper. Sed sapien cras eu vitae commodo. Natoque lobortis maecenas magna neque est amet risus molestie. Arcu dolor sit neque molestie in metus egestas quisque. Pellentesque sagittis in in pulvinar. Facilisis mi non tellus eget tempor sit leo odio. Laoreet ac nunc nulla imperdiet velit at mauris. Bibendum vitae vel aliquet vestibulum cursus. Tincidunt quam ornare in eu fermentum quam tellus arcu. Urna nibh ac velit lorem ultricies proin pellentesque nisl fames. Pretium platea posuere a ultrices mi ut. Risus aliquam nibh lobortis fermentum venenatis sit fringilla. Vel at elit nibh augue in nisi sed. Nec gravida id egestas sodales. Nec ipsum molestie arcu aliquam lacus.\n']
    }, {
        id: 2,
        title: 'A graphic representation of all the incentives sowed by RECs in the last 6 months.',
        background: '../../../assets/pills/pill_02.png',
        backgroundHeading: '../../../assets/pills/pill_heading_02.png'
    }, {
        id: 3,
        title: 'Behind an energy community: \nwho creates it and who manages it.',
        background: '../../../assets/pills/pill_03.png',
        backgroundHeading: '../../../assets/pills/pill_heading_03.png'
    }, {
        id: 4,
        title: 'Do you have solar panels?',
        subtitle: 'Find out about how to record them to acquire more incentives.',
        background: '../../../assets/pills/pill_04.png',
        backgroundHeading: '../../../assets/pills/pill_heading_04.png'
    }, {
        id: 5,
        title: 'Electric mobility: bonuses and refunds for those who buy electric cars, scooters or e-bikes.',
        background: '../../../assets/pills/pill_05.png',
        backgroundHeading: '../../../assets/pills/pill_heading_05.png'
    }, {
        id: 6,
        title: 'How to optimize consumptions with electric devices and LED lamps.',
        subtitle: 'We explain it in an article.',
        background: '../../../assets/pills/pill_06.png',
        backgroundHeading: '../../../assets/pills/pill_heading_06.png'
    }, {
        id: 7,
        title: 'How does self-consumption work and how can we become better prosumers.',
        subtitle: 'Read the infographic.',
        background: '../../../assets/pills/pill_07.png',
        backgroundHeading: '../../../assets/pills/pill_heading_07.png'
    }, {
        id: 8,
        title: 'The fight against CO2 pollution is within our reach.',
        subtitle: 'Here\'s 10 daily tasks to perform to reduce harmful emissions.',
        background: '../../../assets/pills/pill_08.png',
        backgroundHeading: '../../../assets/pills/pill_heading_08.png'
    }]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
