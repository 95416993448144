/**
 * Application configuration properties.
 */
export class CommonsConfig {
  name: string;
  version: string|number;
}

export class Pills {
  id: number;
  title: string;
  subtitle?: string;
  background: string;
  backgroundHeading: string;
  description?: string[];
}
