import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {APP_CONFIG, APP_CONFIG_TOKEN, AppConfig} from './app.config';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

const SHOW_HOMEPAGE = APP_CONFIG.showHomePage;
const SHOW_PILLS = APP_CONFIG.showPills;
const SHOW_INCENTIVES = APP_CONFIG.showIncentives;
const SHOW_FORM = APP_CONFIG.showForm;

@Injectable()
export class EnvironmentGuard implements CanActivate {

    redirectToForm: boolean;

    constructor(@Inject(APP_CONFIG_TOKEN) private readonly cfg: AppConfig,
                private readonly router: Router) {
        this.redirectToForm = !SHOW_HOMEPAGE && !SHOW_PILLS && !SHOW_INCENTIVES && SHOW_FORM;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // We only manage the case where we redirect to the form as of now
        if (state.url !== '/c/application-form' && this.redirectToForm) {
            return of(false).pipe(tap(() => this.router.navigate(['c/', 'application-form'])));
        }
        return of(true);
    }
}
