import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment-timezone';
import {Moment} from 'moment-timezone';

export interface SpinnerData {
    loading: boolean;
    loadingMessage: string;
    abort?: boolean;
}

const TIMEOUT = 1800; // loading animations must be shown for a minimum time interval

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {

    private destroyed = new Subject<void>();
    private now: Moment;
    private timeout: NodeJS.Timeout;

    @Input() spinnerDataSubj: BehaviorSubject<SpinnerData>;
    @Output() closeSpinner: EventEmitter<void> = new EventEmitter<void>();

    spinnerData: SpinnerData;


    constructor() {
    }

    ngOnInit() {
        this.spinnerDataSubj.pipe(
            takeUntil(this.destroyed)
        ).subscribe(sd => {
            if (sd.abort) {
                clearTimeout(this.timeout);
                this.closeSpinner.emit();
            } else {
                // start loading
                if (sd.loading) {
                    this.spinnerData = sd;
                    this.now = moment();
                    this.timeout = setTimeout(() => {
                    }, TIMEOUT);
                }
                // when loading is complete but the action was faster than the animation wait for the first animation to complete
                if (!sd.loading && moment().diff(this.now, 'milliseconds') < TIMEOUT) {
                    this.timeout = setTimeout(() => {
                        this.spinnerData = sd;
                        this.timeout = setTimeout(() => {
                            this.closeSpinner.emit();
                        }, TIMEOUT);
                    }, TIMEOUT - moment().diff(this.now, 'milliseconds'));
                }
                // when loading is complete and the action was slower than the animation switch animation
                if (!sd.loading && moment().diff(this.now, 'milliseconds') >= TIMEOUT) {
                    this.spinnerData = sd;
                    this.timeout = setTimeout(() => {
                        this.closeSpinner.emit();
                    }, TIMEOUT);
                }
            }
        });
    }


    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }


}
