import {Injectable} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {HttpErrorResponse} from '@angular/common/http';
import {isDefined} from '../commons/commons';

/**
 * Service that provides toast-based message box displaying capabilities.
 */
@Injectable()
export class MsgBoxService {

    constructor(private readonly toaster: ToasterService) {
    }

    info(msg: string, showToaster: boolean = true) {
        console.log(msg);
        if (showToaster) {
            this.toaster.pop({
                type: 'success',
                body: msg
            });
        }
    }

    error(msg: string, err?: Error, showToaster: boolean = true) {
        console.error(`${msg}${err ? ' [' + errorMessage(err) + ']' : ''}`);
        if (showToaster) {
            this.toaster.pop({
                type: 'error',
                body: msg
            });
        }
    }
}

/**
 * Extracts the message of a given error.
 * HTTP errors expose status code as well.
 *
 * @param err The error
 * @returns Error message
 */
function errorMessage(err: Error): string {
    if (err instanceof HttpErrorResponse) {
        const msg = err.error?.message ? err.error.message : err.message;
        return msg ? `[${err.status}]: ${msg}${isDefined(err.error.code) ? ' (' + err.error.code + ')' : ''}` : `[${err.status}]: Unknown error`;
    }
    return err.message;
}
