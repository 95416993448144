<div class="application-pills-container">
    <div *ngIf="SHOW_BREADCRUMB" class="upper-banner" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="6px">
        <a [routerLink]="['/c', 'application']">{{'Energy communities'|i18n}}</a>
        <div fxLayout="row" fxLayoutGap="6px">
            <span class="major-sign">></span><a [routerLink]="['/c', 'application-pills']">{{'Informative pills'|i18n}}</a>
        </div>
    </div>
    <div class="pills-section" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="72px">
        <div class="pills-title">
            {{('Informative pills'|i18n).toUpperCase()}}
        </div>
        <div class="pills-subtitle">
            <div [innerHTML]="SUGGEST|i18n"></div>
        </div>
        <div>
            <mat-grid-list cols=3 [rowHeight]="rapport" gutterSize="12px" class="custom-grid">
                <ng-container *ngFor="let pill of pills">
                    <mat-grid-tile class="image-default" [colspan]="pill.cols"
                                   [style.background-image] = "'url(' + pill.background + ')'">
                        <div class="pl-40 pr-40 pt-24 pb-24" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="8px">
                            <div class="image-text">{{pills.indexOf(pill) + 1}}. {{pill.title|i18n}}</div>
                            <div *ngIf="pill.subtitle" class="image-text">{{pill.subtitle|i18n}}</div>
                            <button app-ghost-button class="image-button"
                                    [routerLink]="['/c', 'pill-content', (pill.id).toString()]">{{'Read about it'|i18n}}</button>
                        </div>
                    </mat-grid-tile>
                </ng-container>
            </mat-grid-list>
        </div>
    </div>
    <div class="second-pills-section">
        <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="48px">
            <div class="pills-title">
                {{'Participate now'|i18n}}
            </div>
            <div fxLayout="column" class="pills-subtitle" fxLayoutAlign="space-between center" fxLayoutGap="12px">
                <div>
                    {{PARTICIPATE|i18n}}
                </div>
                <div>
                    {{PARTICIPATE_PT2|i18n}}
                </div>
            </div>
            <button app-primary-button [routerLink]="['/c', 'application-form']"
                    class="request-button" fxLayoutAlign="center center">
                <span class="header-button-text">{{'Request to participate'|i18n}}</span>
            </button>
        </div>
    </div>
</div>
