<div class="application-incentives-container">
    <div *ngIf="SHOW_BREADCRUMB" class="top-banner" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="6px">
        <a [routerLink]="['/c', 'application']">{{'Energy communities'|i18n}}</a>
        <div fxLayout="row" fxLayoutGap="6px">
            <span class="major-sign">></span><a [routerLink]="['/c', 'application-incentives']">{{'State incentives'|i18n}}</a>
        </div>
    </div>
    <div class="main-section" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="72px">
        <div class="title">
            {{('State incentives'|i18n).toUpperCase()}}
        </div>
        <div fxLayoutAlign="center">
            <img alt="Pill image" src='../../../assets/pills/pill_heading_03.png' class="pill-image" appBusy>
        </div>
        <div>
            <mat-accordion multi class="accordion">
                <mat-expansion-panel class="panel-header mat-elevation-z0">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                        <mat-panel-title class="paragraph-title">{{'Who can be part of a REC?'|i18n}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row" fxLayoutGap="32px" class="paragraph-text mt-16">
                        {{'The Renewable Energy Community (REC) is a legal entity, formed by individuals, small and medium-sized enterprises (SMEs), territorial entities or local authorities.'|i18n}}
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="panel-header mat-elevation-z0">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                        <mat-panel-title class="paragraph-title">{{'Who can be part of a SCG?'|i18n}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="32px" class="paragraph-text mt-16">
                        {{'Subjects who belong to the same condominium, building or townhouse complex (group of housing units that share a common part).'|i18n}}
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="panel-header mat-elevation-z0">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                        <mat-panel-title class="paragraph-title">{{'What is the shared energy?'|i18n}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="32px" class="paragraph-text mt-16">
                        {{'For each hour, shared energy is defined as the minimum between energy fed into the distribution grid and energy withdrawn from the grid by all participants in the community (REC or SCG).'|i18n}}
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="panel-header mat-elevation-z0">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                        <mat-panel-title class="paragraph-title">{{'How do incentives work for renewable communities?'|i18n}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="32px" class="paragraph-text mt-16">
                        {{INCENTIVE_REC|i18n}}
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="panel-header mat-elevation-z0">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                        <mat-panel-title class="paragraph-title">{{'How do incentives work for self-consumers groups?'|i18n}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="32px" class="paragraph-text mt-16">
                        {{INCENTIVE_SCG|i18n}}
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="panel-header mat-elevation-z0">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                        <mat-panel-title class="paragraph-title">{{'How do you apply for incentives?'|i18n}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="32px" class="paragraph-text mt-16">
                        <div [innerHTML]="INCENTIVE_ASK|i18n"></div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="panel-header mat-elevation-z0">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                        <mat-panel-title class="paragraph-title">{{'When does the incentive start?'|i18n}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="32px" class="paragraph-text mt-16">
                        {{INCENTIVE_START|i18n}}
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="panel-header mat-elevation-z0">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                        <mat-panel-title class="paragraph-title">{{'What happens to my current electricity supply contract?'|i18n}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="32px" class="paragraph-text mt-16">
                        {{'Every member can keep their own contract, as you are free to decide your electricity supplier.'|i18n}}
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="panel-header mat-elevation-z0">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                        <mat-panel-title class="paragraph-title">{{'What renewable facilities are allowed in an energy community?'|i18n}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="32px" class="paragraph-text mt-16">
                        {{WHICH_PLANTS|i18n}}
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="panel-header mat-elevation-z0">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                        <mat-panel-title class="paragraph-title">{{'Are there "not eligible plants" for the shared energy premium incentive rate?'|i18n}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="8px" class="paragraph-text mt-16">
                        <div>
                            {{'Yes, the following new installations are not eligible for incentives:'|i18n}}
                        </div>
                        <div fxLayout="column" fxLayoutGap="2px">
                            <div>1. {{'PV plants which have access to Superbonus 110%'|i18n}};</div>
                            <div>2. {{'To the quota of new constructions'|i18n}};</div>
                            <div>3. {{'PV plants whose modules are located in rural areas'|i18n}}.</div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
