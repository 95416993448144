<div class="application-container">
    <div class="main-banner" [ngStyle]="{'background-image': 'url(\'../../../assets/application-banner-main.png\')'}">
        <div class="all-titles" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="32px">
            <div class="title" fxLayoutAlign="center center">
                {{'WE BRING YOU TO THE ENERGY COMMUNITY'|i18n}}
            </div>
            <div class="subtitle" fxLayoutAlign="center center">
                {{'An energy community may be waiting for you'|i18n}}
            </div>
            <button app-primary-button [routerLink]="['/c', 'application-form']"
                    class="banner-button" fxLayoutAlign="center center">
                <span class="header-button-text">{{'Request to participate'|i18n}}</span>
            </button>
        </div>
    </div>
    <div class="second-banner" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
        <div class="second-title">
            {{'What is a renewable energy community? (REC)'|i18n}}
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutGap="48px">
            <div fxFlex="33" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
                <app-icon class="icons" name="fal-globe-europe"></app-icon>
                <div class="second-subtitle">
                    {{'What is it?'|i18n}}
                </div>
                <div class="second-paragraph">
                    <div [innerHTML]="FIRST|i18n"></div>
                </div>
            </div>
            <div fxFlex="33" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
                <app-icon class="icons" name="fal-solar-panel"></app-icon>
                <div class="second-subtitle">
                    {{'The goal'|i18n}}
                </div>
                <div class="second-paragraph">
                    <div [innerHTML]="SECOND|i18n"></div>
                </div>
            </div>
            <div fxFlex="33" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
                <app-icon class="icons" name="fal-users"></app-icon>
                <div class="second-subtitle">
                    {{'The members'|i18n}}
                </div>
                <div class="second-paragraph">
                    <div [innerHTML]="THIRD|i18n"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="third-banner" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap="48px">
        <div>
            <img alt="Multiple videos photo" src="../../../assets/application-banner-2.png" class="videos-photo" appBusy>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="space-between center" fxLayoutGap="32px">
            <div class="third-title">
                {{'What you need to know, in 5 minutes'|i18n}}
            </div>
            <div class="third-subtitle">
                {{NEED|i18n}}
            </div>
            <div class="third-subtitle">
                {{EXPLAIN|i18n}}
                <ul>
                    <li>{{'What it means to be a part of an energy community'|i18n}}</li>
                    <li>{{'Who can participate and how'|i18n}}</li>
                    <li>{{'How you can obtain incentives to save on your energy bills'|i18n}}</li>
                </ul>
            </div>
            <button app-primary-button [routerLink]="['/c', 'application-pills']"
                    class="request-button" fxLayoutAlign="center center">
                <span class="header-button-text">{{'Discover the informative pills'|i18n}}</span>
            </button>
        </div>
    </div>
    <div class="fourth-banner" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap="72px">
        <div fxFlex="50" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="space-between center"  fxLayoutGap="32px">
            <div class="fourth-title">
                {{'Save on your bills due to incentives'|i18n}}
            </div>
            <div class="fourth-subtitle">
                {{BILLS|i18n}}
            </div>
            <div class="fourth-subtitle">
                {{BILLS_PT2|i18n}}
            </div>
            <button app-primary-button [routerLink]="['/c', 'application-incentives']"
                    class="request-button" fxLayoutAlign="center center">
                <span class="header-button-text">{{'Find out more'|i18n}}</span>
            </button>
        </div>
        <div>
            <img alt="Mother daughter" src="../../../assets/application-banner-3.png" class="mother-photo" appBusy>
        </div>
    </div>
    <div class="fifth-banner">
        <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="48px">
            <div class="fifth-title">
                {{'How to participate'|i18n}}
            </div>
            <div fxLayout="column" class="fifth-subtitle" fxLayoutAlign="space-between center" fxLayoutGap="12px">
                <div>
                    {{PARTICIPATE|i18n}}
                </div>
                <div>
                    {{PARTICIPATE_PT2|i18n}}
                </div>
            </div>
            <button app-primary-button [routerLink]="['/c', 'application-form']"
                    class="request-button" fxLayoutAlign="center center">
                <span class="header-button-text">{{'Request to participate'|i18n}}</span>
            </button>
        </div>
    </div>
</div>






