import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PrivateDialogData} from './dialog.service';
import {faTimes} from '@fortawesome/pro-light-svg-icons';

interface Context<T> {
    close: (result?: any) => void;
    data: T;
}

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent<T> {

    faTimes = faTimes;

    constructor(private readonly dialogRef: MatDialogRef<DialogComponent<T>>,
                @Inject(MAT_DIALOG_DATA) readonly data: PrivateDialogData<T>) {
    }

    get context(): Context<T> & { $implicit: T } {
        return {
            close: this.close.bind(this),
            data: this.data.context,
            $implicit: this.data.context
        };
    }

    close(result?: any) {
        this.dialogRef.close(result);
    }
}
